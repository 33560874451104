import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon48 from '../../assets/img/service/style2/main-img/48.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="MetaQualt’s Salesforce AppExchange App Development Services"
                    descClass="desc text-dark"
                    description="At MetaQualt, we provide cutting-edge Salesforce AppExchange app development services to help businesses unlock the full potential of the Salesforce platform. Whether you’re looking for custom app development, seamless integrations, or industry-specific solutions, we deliver innovative apps that drive efficiency, productivity, and ROI."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Custom App Development"
                           Text="Build bespoke, scalable apps tailored to meet your specific business requirements.."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Managed Package Development"
                           Text="Develop secure, scalable applications fully compliant with Salesforce AppExchange standards to ensure smooth deployment."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Integration Solutions"
                           Text="Seamlessly integrate Salesforce with third-party platforms to create efficient workflows and streamline operations.
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Utility Apps"
                            Text="Create specialised tools like email parsing, data cleansing, and lead assignment apps to simplify business processes."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Vertical-Specific Apps"
                           Text="Design industry-focused solutions for sectors like healthcare, real estate, retail, manufacturing, and more."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="AI-Powered Apps"
                            Text="Harness the power of Salesforce Einstein to deliver predictive analytics, intelligent automation, and actionable insights.
"
                        />
                    </div>
                  
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Build Next-Gen Fintech App with Us!"
                ctaTextClass="exp-text"
                ctaText="Let's s peak about it. Validate your concept for free with an experienced Fintech expert. Schedule your call right now."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;