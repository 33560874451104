import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg from "../../assets/img/banner/Applicationintigration2.png";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";
import projectImg7 from '../../assets/img/bg/case-study.jpg';
// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/40.png";
import imgMain41 from "../../assets/img/service/style2/main-img/41.png";
import imgMain42 from "../../assets/img/service/style2/main-img/42.png";
import imgMain43 from "../../assets/img/service/style2/main-img/43.png";
import imgMain28 from "../../assets/img/service/style2/main-img/28.png";
import whychoosimg from "../../assets/img/banner/whychoosimg1.png";
import counerimg from "../../assets/img/banner/counterbg1.png";

// Service Image Icons
import serviceIcon14 from "../../assets/img/service/style2/main-img/14.png";
import serviceIcon1 from "../../assets/img/service/style2/main-img/1.png";
import serviceIcon15 from "../../assets/img/service/style2/main-img/15.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import { CounterSection } from "../../components/CounterSection/Data";
import { Feature_Item } from "../../components/CounterSection/Data";
import mean_1 from "../../assets/img/about/application3.png";
import OurServices from "./weProvide";
import WhyChoose from "./whychooseus";

const ApplicationIntagration_Main = () => {
  return (
    <React.Fragment>
    {/* <!-- banner section start --> */}
    <div className="rs-about pt-150 pb-50 md-pt-60">
      <div className="container">
        <div className="row pb-0">
          
        <div className="col-lg-5 col-md-12">
            <div className="text-center">
              <img className="main" src={bannerImg} alt="" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 pl-20">
            {/* Section Title Start */}
            <SectionTitle
              sectionClass="sec-title2 mb-30"
              subtitleClass="sub-text style-bg"
              subtitle="We are Different"
              titleClass="title pb-25"
              title="MetaQualt – Your Trusted Partner for Application Integration

                              "
              descClass="desc pb-5"
              description="Stay ahead in today’s fast-paced business world with agile, seamless integration that drives success. MetaQualt helps you connect business applications, databases, and business intelligence systems effortlessly, ensuring smooth data flow across your organization with our cutting-edge application integration services.
From simple point-to-point integrations to more complex, strategic connections between enterprise applications, we offer innovative solutions that break down data silos. Our services eliminate inefficiencies, streamline processes, and ensure data accuracy, empowering your business to operate with maximum productivity and consistency. Choose MetaQualt to accelerate your business and stay ahead of the competition.
"

            />
            <ConsultNowBtn />
          </div>
     
        </div>
        <div className="shape-image">
          <img className="top dance" src={effectImg2} alt="" />
          <img className="bottom dance" src={effectImg3} alt="" />
        </div>
        {/* counter area start */}
        {/* <CounterSectioin data={data} featureItemsData={featureData} /> */}
        {/* <CounterHomeFive /> */}
        {/* counter area end */}
      </div>
    </div>
    
    <div className="style3 pt-100 pb-100 md-pt-80 md-pb-80">
      <div className="container relative">
        <div className="sec-left">
          <h2 className="title"> What We Provide</h2>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12">
            <div className="row align-items-left">
              <div className="col-lg-12 col-md-12">
                <SectionTitle
                  sectionClass="sec-title2 text-left mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle="Application  Integration Services"
                  titleClass="title pb-10"
                  title="Why Software Integration is a Game-Changer
                                      "
                  descClass="desc"
                  description="In today’s fast-changing business landscape, the ability to quickly adapt and implement changes is essential. Enterprise application integration (EAI) empowers your business to improve operations, enhance data collection and analysis, and boost ROI by enabling seamless information exchange across your systems.tructure, we guarantee a seamless migration journey with minimal disruption.

                                      "
                />
              </div>

              <div className="col-lg-12 col-md-12">
                <ul className="check-square">
                  <li className=""> Helps reduce errors through seamless integration in software, ultimately unlocking your total potential of its software.
                  </li>
                  <li className="">Synchronize data, reduce development costs by using SOA, and enhance operational efficiency with MetaQualt.</li>
                  <li className=""> Leverage the power of one IT infrastructure to optimize performance and collaboration across partners, customers, vendors, and teams.</li>
                  <li className="">Save costs and integrate and upgrade software with increasing agility, compliance, and flexibility.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12">
            <div className="text-center relative">
              <img className="main" src={mean_1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>


    <OurServices />
    {/* rs-services-area-start */}

    <WhyChoose />

    <div className="accordions pb-50 pt-70">
        <div className="container">
          <FAQHeader data={faqDescriptions["Application_Integration_Services"]} />

          <AccordionCards data={accordions.Application_Integration_Services} />
        </div>
      </div>

    {/* rs-services-area-start */}
    {/* <Technologies /> */}
    {/* rs-services-area-start */}

    {/* rs-services-area-start */}
  
    {/* rs-services-area-start */}

    {/* rs-servicess-area-start */}
    {/* <div className="rs-services style8 pt-80 pb-50">
              <div className="container">
                  <SectionTitle
                      sectionClass="sec-title2 text-center mb-44"
                      subtitleClass="sub-text gold-color"
                      subtitle="Work For Any Industry"
                      titleClass="title"
                      title="Best Solutions, For All Organizations"
                      effectClass="heading-line"
                  />
                  <div className="all-services">
                      
                      <SingleServiceFour
                          serviceIcon={img2}
                          Title="Fintech"
                          solutionURL="./fintech-app-solution" 
                      />
                      <SingleServiceFour
                          serviceIcon={img3}
                          Title="Healthcare" 
                          solutionURL="./healthcare-app-solution"
                      />
                     
                      <SingleServiceFour
                          serviceIcon={img13}
                          Title="E-Commerce" 
                          solutionURL="./ecommerce-solutions"
                      />
                      <SingleServiceFour
                          serviceIcon={img16}
                          Title="Fitness-Wellness" 
                          solutionURL="./fitness-wellness-app-solutions"
                      />
                      <SingleServiceFour
                          serviceIcon={img15}
                          Title="Real-Estate" 
                          solutionURL="./real-estate-app-solution"
                      />
                      <SingleServiceFour
                          serviceIcon={img17}
                          Title="Travel"
                          solutionURL="./travel-app-solutions" 
                      />
                      <SingleServiceFour
                          serviceIcon={img14}
                          Title="Buiness App"
                          solutionURL="./business-app-solution" 
                      />
                       <SingleServiceFour
                          serviceIcon={img11}
                          Title="Social Networking" 
                          solutionURL="./social-media-app"
                      />
                  </div>
              </div>
          </div> */}
    {/* rs-services-area-start */}

    {/* newsletter-area-start */}

    

    {/* newsletter-area-end */}

    {/* working-process-area-start */}
    {/* <div className="rs-process style2 pt-80 pb-80">
              <div className="container">
                  <SectionTitle
                      sectionClass="sec-title2 text-center mb-46"
                      subtitleClass="sub-text gold-color"
                      subtitle="Process"
                      titleClass="title title"
                      title="How we Works"
                      effectClass="heading-line" 
                      descClass="desc w-80"
                      description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                      
                  />
                  <div className="row">
                      <div className="col-lg-3 col-sm-6 md-mb-50">
                          <SingleProcess
                              itemClass="addon-process"
                              processImage={processImage1}
                              titleClass="title"
                              Title="Discover & Define"
                          />
                      </div>
                      <div className="col-lg-3 col-sm-6 md-mb-50">
                          <SingleProcess
                              itemClass="addon-process"
                              processImage={processImage13}
                              titleClass="title"
                              Title="Designing & Development"
                          />
                      </div>
                      <div className="col-lg-3 col-sm-6 sm-mb-50">
                          <SingleProcess
                              itemClass="addon-process"
                              processImage={processImage14}
                              titleClass="title"
                              Title="Testing & Deploying"
                          />
                      </div>
                      <div className="col-lg-3 col-sm-6">
                          <SingleProcess
                              itemClass="addon-process"
                              processImage={processImage15}
                              titleClass="title"
                              Title="Deliver & Maintenance"
                          />
                      </div>
                  </div>
              </div>
          </div> */}
    {/* working-process-area-end */}

    {/* brand-area-start */}
    {/* <Brand /> */}
    {/* brand-area-end */}
  </React.Fragment>
  )
}

export default ApplicationIntagration_Main