import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg from "../../assets/img/banner/applicationmin12.png";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";
import projectImg7 from '../../assets/img/bg/case-study.jpg';
// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/40.png";
import imgMain41 from "../../assets/img/service/style2/main-img/41.png";
import imgMain42 from "../../assets/img/service/style2/main-img/42.png";
import imgMain43 from "../../assets/img/service/style2/main-img/43.png";
import imgMain28 from "../../assets/img/service/style2/main-img/28.png";
import whychoosimg from "../../assets/img/banner/whychoosimg1.png";
import counerimg from "../../assets/img/banner/counterbg1.png";

// Service Image Icons
import serviceIcon14 from "../../assets/img/service/style2/main-img/14.png";
import serviceIcon1 from "../../assets/img/service/style2/main-img/1.png";
import serviceIcon15 from "../../assets/img/service/style2/main-img/15.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import { CounterSection } from "../../components/CounterSection/Data";
import { Feature_Item } from "../../components/CounterSection/Data";
import mean_stack1 from "../../assets/img/about/appliint12.png";
import OurServices from "./weProvide";
import WhyChoose from "./whychooseus";



const application_migration  = () => {
  let data = CounterSection.machinelearning[0];
  const featureData = Feature_Item.machinelearning;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
           
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="We are Different"
                titleClass="title pb-25"
                title="Future-Proof Your Business with Scalable, Secure, and Agile IT Solutions


                                "
                descClass="desc pb-5"
                description="Outdated systems can hold your business back in today's fast-paced digital landscape. At MetaQualt, our Application Migration Services are designed to transition your legacy applications to modern, cloud-ready environments seamlessly.
We don’t just migrate applications—we align every technical decision with your business goals. The result? Enhanced scalability, improved resilience, and the agility to adapt to ever-changing market demands.
Every migration is a step towards innovation. With MetaQualt, every decision is backed by strategic insights and measurable outcomes.
Transform your legacy systems. Elevate your business. Choose MetaQualt.
 "

              />
              <ConsultNowBtn />
            </div>
       
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
          {/* counter area start */}
          {/* <CounterSectioin data={data} featureItemsData={featureData} /> */}
          {/* <CounterHomeFive /> */}
          {/* counter area end */}
        </div>
      </div>
      
      <div className="style3 pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text style-bg"
                    subtitle="Application Migration Services"
                    titleClass="title pb-10"
                    title="Application Migration Services
                                        "
                    descClass="desc"
                    description="In today’s fast-paced digital landscape, outdated systems and legacy applications can severely limit your business’s potential for growth, innovation, and security. At MetaQualt, our Application Migration Services empower businesses to seamlessly transition from legacy systems to modern, cloud-ready, and high-performance environments.
We go beyond simple migration—we ensure every technical decision aligns with your long-term business goals, delivering measurable outcomes that drive success. Whether you're moving to the cloud, adopting hybrid solutions, or upgrading on-premise infrastructure, we guarantee a seamless migration journey with minimal disruption.

                                        "
                  />
                </div>

                <div className="col-lg-12 col-md-12">
                  <ul className="check-square">
                    <li className="">Assessment & Planning: Comprehensive evaluation of your existing infrastructure to identify opportunities and risks.
                    </li>
                    <li className="">Strategic Roadmap: Customized migration strategy aligned with your business objectives.</li>
                    <li className="">Seamless Execution: Expertise-driven implementation with minimal disruption.</li>
                    <li className="">Post-Migration Support: Ongoing monitoring, optimization, and technical support.</li>
                    <li className="">Transform your legacy systems. Elevate your business. Choose MetaQualt for smarter, faster, and future-ready application migration.</li>
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">Powerful Frameworks</li>
                    <li className="">Apt for web, software & mobile</li>
                    <li className="">Easier SEO</li>
                    <li className="">Lower Development Cost</li>
                    <li className="">Readable, flexible & secure</li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={mean_stack1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <OurServices />
      {/* rs-services-area-start */}

      <WhyChoose />

      
      <div className="accordions pb-50 pt-80">
        <div className="container">
          <FAQHeader data={faqDescriptions["Application_Migration_Services"]} />

          <AccordionCards data={accordions.Application_Migration_Services} />
        </div>
      </div>

      {/* rs-services-area-start */}
      {/* <Technologies /> */}
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
    
      {/* rs-services-area-start */}

      {/* rs-servicess-area-start */}
      {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
      {/* rs-services-area-start */}

      {/* newsletter-area-start */}

      

      {/* newsletter-area-end */}

      {/* working-process-area-start */}
      {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
      {/* working-process-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}
    </React.Fragment>
  );
};

export default application_migration;
