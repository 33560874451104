import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import Salesforce_implementation from '../../components/Common/Breadcumb/salesforceimp';
import Salesforce_implementation_solution from './Salesforceimplementation.js';

// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';

const Salesforce_implementation_main = () => {

    return (
        <React.Fragment>
           
            <HeaderStyleThree
                parentMenu='about'
                 activeMenu='/about'
            />
            <HeaderStyleThree />
            <main>
                {/* breadcrumb-area-start */}
                < Salesforce_implementation
                    pageTitle="Salesforce Implementation Services"
                    titleClass="page-title"
                    pageName="Salesforce Implementation Services
"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Salesforce Implementation "
                />
                {/* breadcrumb-area-start */}

                {/* About Main */}
                <Salesforce_implementation_solution/>
                {/* About Main */}

                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </main>
            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}

export default Salesforce_implementation_main;