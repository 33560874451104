import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/mobileapplication.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import techImg10 from '../../assets/img/technology/style2/10.svg';
import techImg16 from '../../assets/img/technology/style2/16.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg6 from '../../assets/img/technology/style2/6.svg';
import techImg13 from '../../assets/img/technology/style2/13.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg12 from '../../assets/img/technology/style2/12.svg';

// Service Image Icons
import serviceIcon14 from '../../assets/img/service/style2/main-img/14.png';
import serviceIcon25 from '../../assets/img/service/style2/main-img/25.png';
import serviceIcon44 from '../../assets/img/service/style2/main-img/44.png';
import serviceIcon45 from '../../assets/img/service/style2/main-img/45.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';
import ConsultNowBtn from '../../components/ConsultNowBtn';
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data"
import FAQHeader from "../../components/FAQHeader/FAQHeader";

import { CounterSection } from '../../components/CounterSection/Data';
import CounterSectioin from '../../components/CounterSection/CounterSectioin';
import { Feature_Item } from "../../components/CounterSection/Data";

const MobileAppDevelopment = () => {
    // let data = CounterSection.MobileAppDevelopment[0];
    // const featureData = Feature_Item.MobileAppDevelopment;
    return (
        <React.Fragment>
            
            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-60 md-pt-60">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="Mobile App Development Solutions"
                                titleClass="title pb-25"
                                title="Make your ideas perceptible on Mobile screens.                                 "
                                descClass="desc pb-5"
                                description="Almost everything today is accessible by pulling out that smart guy - “Smartphone” inside your pockets. Our developers have joined this smart flow and successfully delivered mobile solutions that meet the requirements of the ever-changing dynamics of Mobile Apps. We emphasize your brand presence, revenue, and excellence while we develop cutting-edge mobile solutions for you. "
                                secondDescClass="desc pb-16"
                                secondDescription="Discuss your fresh ideas and we will assist you with the best cross-platform and native apps. Touch your screens, and expand your business while we keep up the good work by touching your hearts! "
                            />
                             <ConsultNowBtn />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={bannerImg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
            {/* <!-- banner section end --> */}

            {/* < CounterSectioin data={data} featureItemsData={featureData}  /> */}
            {/* <!-- banner section end --> */}

            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="A great deal of mobile app development services."
                        descClass="desc"
                        description="Hire our mobile app developers and experience the difference between good apps and excellent apps. Our terrific team of developers strives to achieve this result by developing a solution that outplays your competitors and benefits your users. Our proficiency reflects in the ioS, Android, and Cross-platform development services."
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-40"
                                        titleClass="title pb-10 fz-36"
                                        title="Android App Development"
                                        descClass="desc"
                                        description="‘We develop android apps unique to each user,’ is what we tell. Leveraging our expertise in Java, Kotlin, C, C++, HTML, React Native, and CSS and developing excellent android platforms,’ is what we do. ‘A sleek, loyal, and customized lead-generating app,’ is what reflects our work. In short, what we develop reflects what we said we could do." 
                                    />
                                    <div className="rs-technology">
                                        <div className="row">            
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item pt-5 pb-5">
                                                    <a href="#">
                                                        <div className="logo-img">
                                                            <img src={techImg10} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                    <a href="#">
                                                        <div class="logo-img">
                                                        <img src={techImg16} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                    <a href="#">
                                                        <div class="logo-img">
                                                        <img src={techImg3} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Section Title End */}
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./android-app-development">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6 mb-30">
                                <div className="box-shadow">
                                    <SectionTitle
                                        sectionClass="sec-title2 text-left mb-30"
                                        titleClass="title pb-10 fz-36"
                                        title="iOS App Development"
                                        descClass="desc"
                                        description="We master iOS apps from design to maintenance. We have got groundbreaking solutions for iOS users in varied industries across the globe. We take pride in your happy smiles as you use trendy, fast, revenue-generating, and highly engaging iOS apps we design and develop for you. Our trained developers in React Native, Java, and Swift are exceptional creators of personalized iOS apps."  
                                    />
                                    <div className="rs-technology">
                                        <div className="row">            
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div className="technology-item">
                                                    <a href="#">
                                                        <div className="logo-img">
                                                            <img src={techImg6} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item pt-5 pb-5">
                                                    <a href="#">
                                                        <div class="logo-img">
                                                        <img src={techImg10} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                <div class="technology-item">
                                                    <a href="#">
                                                        <div class="logo-img">
                                                        <img src={techImg3} alt="" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-part">
                                        <Link className="readon learn-more" style={{paddingTop : 8, paddingBottom : 8 }} to="./ios-app-development">Read More</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 mb-20"></div>
                            <div className="col-lg-8 mb-30 box-shadow">
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    titleClass="title pb-10 fz-36"
                                    title="Cross-platform App Development"
                                    descClass="desc"
                                   description="Dreaming of influencing a larger audience? We have got cross-platform development services that make not just us, the creators, but you, our clients, look smart. Our sturdy solution will ensure you reach a massive audience quickly with affordable prices. Anticipate huge growth as we set our tech excavators to dig out the best solution using the frameworks like Reach Native, Kotlin, Xamarin, Flutter, HTML, JavaScript, and CSS."  
                                />
                                <div className="rs-technology">
                                    <div className="row">            
                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="technology-item">
                                                <a href="#">
                                                    <div className="logo-img">
                                                        <img src={techImg3} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="technology-item">
                                                <a href="#">
                                                    <div className="logo-img">
                                                        <img src={techImg16} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div class="technology-item">
                                                <a href="#">
                                                    <div class="logo-img">
                                                    <img src={techImg12} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div class="technology-item">
                                                <a href="#">
                                                    <div class="logo-img">
                                                    <img src={techImg13} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div class="technology-item">
                                                <a href="#">
                                                    <div class="logo-img">
                                                    <img src={techImg8} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div class="technology-item">
                                                <a href="#">
                                                    <div class="logo-img">
                                                    <img src={techImg4} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div class="technology-item">
                                                <a href="#">
                                                    <div class="logo-img">
                                                    <img src={techImg9} alt="" />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="col-lg-2 mb-20"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}
{/* 
            <Technologies /> */}

            {/* rs-services-area-start */}
            <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-60"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Our Developer’s Approach and Workflow"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="Have a mobile app idea at the back of your mind? Feel free to share with us and we will take care that it takes a handsome shape. You name any existing industry and our team makes sure to develop a future-proof mobile app. Whether it’s a new app or redesigning an already developed app, we have got elite techies to develop best-in-class products. "
                    />
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon14}
                                Title="Proven Specialization"
                                Text="We have been in the app development industry for almost a decade. Our app developers have juiced up numerous mobile app ideas and turned them into living platforms to generate uncountable sales for our users."
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon45}
                                Title="Proactive Approach" 
                                Text="That trendy app our users tap into and get satisfying sales is the result of our proactive approach. We keep ourselves updated to survive the ever-changing dynamics in the mobile app industry - the trending designs, likewise the advancement in frameworks. 
                                " 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon44}
                                Title="Mobile-First Team" 
                                Text="Our mobile-first team is reckoned top-notch in delivering tailor-made apps for a variety of platforms. Just like in web development, we also think big to give you a full-fledged mobile development solution for your enterprise." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 mb-30">
                            <SingleService 
                                itemClass="services-item"
                                serviceImage={serviceIcon25}
                                Title="Result-Driven" 
                                Text="After your app is set to boom your business, you gain the upper hand over the competitive digital world. Our end-to-end services start from ideation to maintenance. We are attuned to your ideas, specifications, your end users, and ambitions.
                                " 
                            />
                        </div>
                    </div>
            </div>
            </div>
            {/* rs-services-area-start */}
            
            {/* rs-servicess-area-start */}
            {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            <div className="accordions pb-50">
                <div className="container">
                    <FAQHeader data={faqDescriptions["MobileAppDevelopment"]} />

                    <AccordionCards data={accordions.MobileAppDevelopment} />
                </div>
            </div>
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-80"
				ctaTitleClass="epx-title"
				ctaTitle="Hire the Dedicated Mobile App Developers"
				ctaTextClass="exp-text"
				ctaText="Let your ideas traverse the right stream of brainstorming, branding, web interface, and logo design to turn them into irresistible web apps. With our Mobile App masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}

             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title5"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default MobileAppDevelopment;