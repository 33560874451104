import blogImg1 from "../../assets/img/blog/inner/1.jpg";
import blogImg2 from "../../assets/img/blog/inner/2.jpg";
import blogImg3 from "../../assets/img/blog/inner/3.jpg";
import blogImg4 from "../../assets/img/blog/inner/4.jpg";
import blogImg5 from "../../assets/img/blog/inner/5.jpg";
import blogImg6 from "../../assets/img/blog/inner/6.jpg";
import blogImg7 from "../../assets/img/blog/inner/7.jpg";
import blogImg8 from "../../assets/img/blog/inner/8.jpg";
import blogImg9 from "../../assets/img/blog/inner/9.jpg";
import blogImg10 from "../../assets/img/blog/inner/10.jpg";
import postImage from "../../assets/img/blog/inner/Metaqualt_blog_image (1).png";
import postImg2 from "../../assets/img/blog/inner/Metaqualt_blog_image.png";

export const data = [

  {
    id: 1,
    blogImage: postImage,
    blogCategory: "Application Testing",
    blogPublishedDate: "November 20, 2024",
    blogTitle: "Why Offshore Staff Augmentation Makes Sense",
    blogDesc:
      "In the fast-changing world of technology, companies need smart strategies to grow quickly and stay ahead of the competition. One powerful solution is offshore staff augmentation, especially in a country like India.",
    blogButtonClass: "blog-button inner-blog",
  },
  {
    id: 2,
    blogImage:  postImg2,
    blogCategory: "Application Testing",
    blogPublishedDate: "January 07, 2025",
    blogTitle: "Types of IT Staff Augmentation for Agile Businesses",
    blogDesc:
      "MetaQualt recognizes that developing a dynamic, future-ready workforce demands flexibility and precision. IT staff augmentation is not just filling gaps but scaling your team with specialized talent to accelerate growth and innovation.",
    blogButtonClass: "blog-button inner-blog",
  },

];




