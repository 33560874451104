import React from "react";
import List from "./List";

const Description = ({ title, points, paragraph, subtitle }) => {


  return (
    <>
      <h6>{title}</h6>
      {paragraph && <p>{paragraph}</p>}
      {subtitle && <p>{subtitle}</p> }
      {points && points.map((desc, index) => <List key={index} {...desc} />)}
    </>
  );
};

export default Description;
