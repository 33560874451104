import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../Common/SectionTitle';
import SingleProject from './SingleProject';
import Fintech from './fintech';
import Healthcare from './healthcare';
import Travel from './travel';
import Real_estate from './real-estate';
import Business_app from './business_app';
import Fitness from './fitness';




import projectImgP1 from '../../assets/img/project/P12.png';
import projectImgP13 from '../../assets/img/project/P13.png';
import projectImgP2 from '../../assets/img/project/P2.jpg';
import projectImgP3 from '../../assets/img/project/P3.png';
import projectImgP4 from '../../assets/img/project/business_app.png';
import projectImgS3 from '../../assets/img/project/real-estate.png';
import projectImgP6 from '../../assets/img/project/P6.jpg';
import projectImgP7 from '../../assets/img/project/fitness_app.png';
import projectImgP11 from '../../assets/img/project/travel_app.png';
import projectImgS2 from '../../assets/img/project/S2.jpg';


const Project = () => {

    const options = {
        items: 3,
        nav: true,
        dots: false,
        margin: 30,
        rewind: false,
        autoplay: false,
        stagePadding: 30,
        loop: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        center: false,
        responsive: {
            0: {
                stagePadding: 0,
                items: 1,
                nav: false,
            },
            768: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            992: {
                items: 2,
                nav: false,
                stagePadding: 0,
            },
            1200: {
                items: 3,
                nav: true,
                stagePadding: 0,
            },
            1500: {
                items: 3,
                stagePadding: 0,
            }
        }
    };

	return(
        <React.Fragment>
            <div id="rs-portfolio" className="rs-project style1 pt-80 pb-50 md-pt-80 mb-50 home_inner">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44 md-mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Top Industries We Serve"
                        titleClass="title"
                        title="We offer the most Client-Satisfying Services in varied verticals."
                        descClass="descr"
                        description="We cover all commercial sectors when it comes to reinventing company operations by giving clever solutions that work. Your e-commerce gets a buoy up by our methodical, reliable, and creative solutions."
                        effectClass="heading-line"
                        />
                    <OwlCarousel options={options} >
                        
                        <Fintech 
                            itemClass="project-item"
                            projectImage={projectImgP1} 
                            Title="FINTECH" 
                            // Text="Make your customers digital banking and investing experience smooth without irritation like banking long queue lines."
                        />
                        <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgP3} 
                            Title="E-COMMERCE" 
                       
                        />
                        {/* <SingleProject 
                            itemClass="project-item"
                            projectImage={projectImgP2} 
                            Title="EDUCATION" 
                            // Text="The rise in online education platforms can be seen after the covid pandemic. To enhance their learning habits, parents nowadays allow even their children to use smart devices to download educational apps."
                       
                       /> */}
                        <Healthcare 
                            itemClass="project-item"
                            projectImage={projectImgP13} 
                            Title="HEALTHCARE" 
                            // Text="Having entertainment in our lives and on the weekends to burn out our stress is crucial. Let's give your users the best entertainment experience they need to relieve stress and get back to work."
                        
                        />
                        <Travel 
                            itemClass="project-item"
                            projectImage={projectImgP11} 
                            Title="TRAVEL" 
                            // Text="Provide your users with the ability to watch matches & get every update from anywhere without having to purchase expensive tickets or sit in long lines." 
                        />
                        <Real_estate 
                            itemClass="project-item"
                            projectImage={projectImgS3} 
                            Title="REAL-ESTATE" 
                            // Text="Provide your users with the ability to watch matches & get every update from anywhere without having to purchase expensive tickets or sit in long lines."
                             
                        />
                        <Business_app 
                            itemClass="project-item"
                            projectImage={projectImgP4} 
                            Title="BUSINESS APP" 
                            // Text="Businesses can modernize their operations by leveraging enterprise mobility solutions to overcome challenges, boost productivity, enhance customer services, and increase revenue."
                       
                       />
                        <Fitness 
                            itemClass="project-item"
                            projectImage={projectImgP7} 
                            Title="HEALTH & FITNESS" 
                            // Text="A healthcare app developed by us allows you to connect with patients in real-time. We develop customized health care mobile app for hospitals, doctors, pharmacists, etc. so that their patients can receive better care."
                       
                       />
                        
                    </OwlCarousel>
                </div>
            </div>
        </React.Fragment>
	)
}

export default Project