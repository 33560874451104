import React from "react";
import SectionTitle from "../../components/Common/SectionTitle/SectionTitle";

// Testimonial Avatars
import aboutImg1 from "../../assets/img/about/SalesforceConsultingRooted.png";

const TechSlider = () => {
  return (
    <React.Fragment>
      <div className="bg18 provide_sec sec_4 style3 pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title text-white">What's We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 md-mb-50">
              <div className="image-part">
                <img src={aboutImg1} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left"
                    subtitleClass="sub-text gold-color"
                    subtitle="Our Top Features "
                    titleClass="title pb-10 text-white"
                    title="MetaQualt’s Salesforce AppExchange App Development Services"
                    descClass="desc text-white"
                    description={
                      <>
                        At MetaQualt, we specialise in creating powerful,
                        tailored solutions for businesses on Salesforce
                        AppExchange. From custom apps to industry-specific
                        innovations, our services are designed to transform your
                        operations and maximise ROI.
                      </>
                    }
                    secondDescClass="secondDesc"
                    secondDescription={<>Our AppExchange Development Expertise:</>}
                  />
                </div>
                
                <div className="col-lg-6 col-md-6 ">
                  <ul className="check-square">
                    <li className="text-white">Custom App Development</li>
                    <li className="text-white">Managed Package Development</li>
                    <li className="text-white">Data Management Tools</li>
                    <li className="text-white">UI/UX Enhancements</li>
                    <li className="text-white">CPQ and Billing Extensions</li>
                    <li className="text-white"> Integration Solutions Ongoing Support and Maintenance</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 pl-0 pr-0">
                  <ul className="check-square">
                    <li className="text-white">Utility Apps</li>
                    <li className="text-white">Vertical-Specific Apps</li>
                    <li className="text-white">AI-Powered Apps</li>
                    <li className="text-white">Ongoing Support and Maintenance</li>
                    <li className="text-white">Security Review Assistance</li>
                    <li className="text-white">Start The Implementation Or Customization</li>
                   
                  </ul>
                </div>

                <div className="col-lg-12 col-md-6 ">
                  <ul className="check-square">
                   
                   
                    
                   
                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TechSlider;
