import React from 'react';
import { Link } from 'react-router-dom';
import bannerbg from '../../../assets/img/banner/about_us.png';

const SiteBreadcrumb = (props) => {
	const { pageTitle, titleClass, parentCategory, pageCategory, pageName, breadcrumbsImg, animatedFont, animatedFontsize } = props;

	const breadcrumbsImgStyle = {
		backgroundImage: `url(${breadcrumbsImg})`
	}
	return (
		<div className="rs-breadcrumbs rs-rain-animate" style={breadcrumbsImgStyle}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12">
						<div className="breadcrumbs-inner pt-300 pb-250 md-pt-200 md-pb-150 sm-pt-180 sm-pb-50">
							<h1 className={titleClass ? titleClass : 'page-title'}>{pageTitle ? pageTitle : 'Breadcrumbs'}</h1>
							<ul>
								<li>
									<Link to="/" className="active">{parentCategory ? parentCategory : 'Home'}</Link>
								</li>
								<li><Link to="/blog" className="">{parentCategory ? parentCategory : 'blog'}</Link>	</li>
								
								<li></li>
							</ul>
						</div>
					</div>
					{/* <div className="col-lg-6 col-md-5 col-sm-12">
						<div className="text-center pt-250 pb-150 md-pt-200 md-pb-120 sm-pt-50 sm-pb-50">
                            <img className="bread_main_img" src={bannerbg} alt=""/>
                        </div>
					</div> */}
				</div>
				<div className="line-inner style2">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                </div>
			</div>
		</div>
	);
}

export default SiteBreadcrumb;




