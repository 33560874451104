import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceSix from '../../components/Service/SingleServiceSix';

// Service Image Icons
import serviceImg15 from '../../assets/img/service/style9/s12.png';
import serviceImg16 from '../../assets/img/service/style9/s15.png';
import serviceImg14 from '../../assets/img/service/style9/ss5.png';
import serviceImg13 from '../../assets/img/service/style9/ss6.png';
import serviceImg11 from '../../assets/img/service/style9/ss7.png';
import serviceImg12 from '../../assets/img/service/style9/ss8.png';
import layerIcon from '../../assets/img/service/style9/layer-icon.png';


const WeServes = () => {

    return (
        <div id="rs-service" className="rs-services style7 pt-80 pb-80 md-pt-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 mb-50 text-center"
                    subtitleClass="sub-text gold-color"
                    subtitle="Salesforcendustry"
                    titleClass="title new-title"
                    title="Expert Salesforce Lightning Consulting with MetaQualt"
                    descClass="desc"
                    description="Unlock the full potential of Salesforce Lightning with MetaQualt’s expert consulting services. Our seasoned Salesforce Lightning consultants collaborate closely with your team to craft a tailored Lightning strategy that redefines your business operations. "
//                     secondDescClass="secondDesc"
//                     secondDescription="Leveraging years of expertise, we design a comprehensive plan that maximises the power of Lightning Components, delivers an intuitive user experience (UX), and streamlines processes through automated workflows. With our strategic approach, we ensure your organisation achieves the highest ROI from Salesforce Lightning.
// "
                    effectClass="heading-line"
                    
                />
                <div className="animation-layer">
                    <div className="shape-layer">
                        <img 
                            className="dance2" 
                            src={layerIcon} 
                            alt="Images" 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg14}
                            Title="Salesforce Enablement
"
                            
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-pink"
                            serviceImg={serviceImg13}
                            Title="Salesforce Platform-as-a-Service
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-green"
                            serviceImg={serviceImg11}
                            Title="Strategy, Vision & Governance" 
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-red"
                            serviceImg={serviceImg12}
                            Title=" Integration Expertise"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width  bg-light-green"
                            serviceImg={serviceImg15}
                            Title="Salesforce integrations – connectors, APIs, & custom development

"
                            
                        />
                    </div>  <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceSix
                            itemClass="services-item img-width bg-light-blue"
                            serviceImg={serviceImg16}
                            Title="AppExchange & 3rd party product integration & operation

"
                            
                        />
                    </div>
                    
                </div>
               
            </div>
        </div>
    );
}

export default WeServes;

