import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import bannerImg from '../../assets/img/banner/web1.png';
import SingleProcess from '../../components/Process/SingleProcess';
import TechSliderModels from './TechSlider';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';

// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain7 from '../../assets/img/service/style2/main-img/7.png';
import imgMain8 from '../../assets/img/service/style2/main-img/8.png';
import imgMain9 from '../../assets/img/service/style2/main-img/9.png';
import imgMain11 from '../../assets/img/service/style2/main-img/11.png';
import imgMain12 from '../../assets/img/service/style2/main-img/12.png';
import imgMain13 from '../../assets/img/service/style2/main-img/13.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
import ConsultNowBtn from '../../components/ConsultNowBtn';
import { CounterSection } from '../../components/CounterSection/Data';
import CounterSectioin from '../../components/CounterSection/CounterSectioin';
import { Feature_Item } from "../../components/CounterSection/Data";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data"
import FAQHeader from "../../components/FAQHeader/FAQHeader";

const WebDevelopment = () => {

    // let data = CounterSection.WebDevelopment[0];
    // const featureData = Feature_Item.WebDevelopment;

    return (
        <React.Fragment>
        {/* rs-servicess-area-start */}    
        <div className="rs-about pt-150 pb-50 md-pt-60">
            <div className="container">
                <div className="row pb-0">
                   
                    <div className="col-lg-6 col-md-12 pl-20 md-pl-5">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title2 mb-30"
                            subtitleClass="sub-text style-bg"
                            subtitle="What We Do"
                            titleClass="title pb-25"
                            title="Contact us for the most resilient Websites & Apps

                            Do you fancy a Website that maximizes your profits?
                            "
                            descClass="desc pb-16"
                            description=""
                            secondDescClass="desc pb-16"
                            secondDescription="If you need a sleek, sturdy, secure, and customized web app, count on us. We are the leading team of developers having expertise in web technologies like ReactJS, Angular, Node.js, Laravel, Golang, and more. Our ability to use open-source technology to create a crackerjack product makes us stand out. We are geared up to provide custom solutions with appealing designs, high loyalty, and massive engagement for any enterprise, business, or consumer. With us, you are on your way to revolutionizing your digital presence like never before!"
                        />
                         <ConsultNowBtn />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="text-center">
                            <img className="main" src={bannerImg} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="shape-image">
                    <img className="top dance" src={effectImg2} alt="" />
                    <img className="bottom dance" src={effectImg3} alt="" />
                </div>
                {/* counter area start */}
                {/* <CounterHomeFive /> */}
                {/* counter area end */}
            </div>
        </div>
         {/* rs-servicess-area-end */} 

         {/* < CounterSectioin data={data} featureItemsData={featureData}  /> */}
           
            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-50 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our services are at the forefront"
                        titleClass="title pb-10"
                        title="We cover all present-day Web Development needs."
                        descClass="desc"
                        description="What if you land on a site that is eye-pleasing, idiot-proof, and  
                        allures you to scroll and dig deeper? What if that site is yours? What if you want your customers to feel the same as you felt now? We will convert your dream ideas into a mind-blowing site. Our team gets the ball rolling by blending modern web development technologies with absorbing designs and interfaces that generate leads. Whether you run a startup or a large eCommerce platform, we give the best we can.
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20 md-pt-50">
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain11}
                                    HoverImg={imgMain11}
                                    Title="Custom Web Development"
                                    Text="We believe that each business is unique. To preserve this uniqueness, we give exclusive attention to each client’s requirements and build a trustworthy and sleek website. We develop a custom and dynamic platform to showcase your talents in the digital domain.  "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain7}
                                    HoverImg={imgMain7}
                                    Title="CMS Website Development"
                                    Text="Are you a blogger willing to share your work via the best CMS platform, WordPress? Our techies build top CMS platforms like WordPress, Drupal, and more. They are top dogs in languages like .NET and .PHP that can build these famed CMS platforms. Make the most of WordPress, which features numerous plugins. Take your brand/blog to new heights."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain8}
                                    HoverImg={imgMain8}
                                    Title="Enterprise Web Development"
                                    Text="Are you in the serious business of generating ROI via your website? Our team sets up a perfect B2B, B2C, intranet, or media website. MetaQualt boasts some of the most experienced developers who develop sites with a high conversion rate. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain12}
                                    HoverImg={imgMain12}
                                    Title="Website Support & Maintenance"
                                    Text="We provide support and maintenance to make sure your site stays as fresh as a daisy. We responsibly keep the site at optimal health by adding new features, managing the server, and implementing timely changes."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain9}
                                    HoverImg={imgMain9}
                                    Title="eCommerce Web Development"
                                    Text="Are you sick of a website that’s just a weeny speck amid the crowd of websites in the digital realm? A website that doesn’t generate sales. Don’t be patient anymore. Reach out to us. Our eCommerce websites are sales-driven. We have a highly motivated team of Shopify, Magento, Bigcommerce, PHP, NET, open source, and PHP developers to scale your eCommerce store. "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain13}
                                    HoverImg={imgMain13}
                                    Title="OpenSource Web Development"
                                    Text="If you want to speed up your web development venture, rely on us. We harness the power of open-source platforms like WordPress, Drupal, and Joomla to create a website fast and as neat as a pin. We also deliver hassle-free security and version updates to you. "
                                    btnClass='d-none'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* rs-services-area-start */}
            <TechSliderModels />
            {/* rs-services-area-start */}

             {/* rs-servicess-area-start */}
             {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */} 

            <div className="accordions pb-50">
                <div className="container">
                    <FAQHeader data={faqDescriptions["Web-development"]} />

                    <AccordionCards data={accordions.Web_Development} />
                </div>
            </div>
            
            {/* newsletter-area-start */}
			<CTA
				ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-50"
				ctaTitleClass="epx-title"
				ctaTitle="Hire The Dedicated Web Developers"
				ctaTextClass="exp-text"
				ctaText="With our web/app development and design services, you can rely on us to do the job right. Our team takes on your vision and solves your challenges. Offering a QUALITY PRODUCT worth your REPUTATION that delivers VALUE for your MONEY."
				ctaButtonClass="readon learn-more"
				ctaButtonLink="#"
				ctaButtonText="Get In Touch"
			/>
			{/* newsletter-area-end */}

                        
             {/* working-process-area-start */}
             {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}  

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default WebDevelopment;