import React from "react";

import WeServes from "./it-services";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleProcess from "../../components/Process/SingleProcess";
import Brand from "../../components/Common/Brand";
import WhyChoose from "./whychooseus";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
import TechSlider from "./TechSlider";
import SingleServiceFour from "../../components/Service/SingleServiceFour";

// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";
import roundImg from "../../assets/img/about/salesforceco.png";
import aboutImg from "../../assets/img/about/salesforceconusulting.png";

// Service Icons

import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";
import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";
import ConsultNowBtn from "../../components/ConsultNowBtn";

const SalesForce_Main = () => {
  const options = {
    items: 3,
    nav: false,
    dots: true,
    margin: 30,
    rewind: false,
    autoplay: false,
    stagePadding: 30,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    loop: true,
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        dots: false,
      },
      768: {
        items: 2,
        stagePadding: 0,
        dots: true,
      },
      992: {
        items: 3,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  return (
    <React.Fragment>
      {/* <!-- banner section end --> */}
      <div id="rs-about" className="rs-about disc style3 pt-150 pb-0 md-pt-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 mb-20">
              <div className="rs-animation-image md-pb-10 md-pt-10">
                <div className="pattern-img">
                  <img src={roundImg} alt="" />
                </div>
                <div className="middle-img ">
                  <img className="dance3" src={aboutImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2  pt-70 md-pt-10 "
                subtitleClass="sub-text gold-color"
                subtitle="WHO WE ARE"
                titleClass="title mb-25"
                title="Reimagine Innovation with MetaQualt’s Salesforce AppExchange Development Services

                                "
                descClass="desc pb-15"
                description="At MetaQualt, we create transformative Salesforce AppExchange solutions that redefine how businesses operate and succeed. As a trusted Salesforce Ridge and ISV Partner, we specialise in crafting robust, user-friendly apps tailored to diverse business needs, empowering organisations to enhance productivity, streamline processes, and deliver exceptional customer experiences."
                secondDescClass="mb-10 pb-16"
                secondDescription="Whether it’s optimising workflows, resolving inefficiencies, or improving user adoption, we tackle your organisation’s pain points with precision, ensuring Salesforce delivers measurable results and aligns perfectly with your business objectives.
"
              />

              {/* <div>
                <h3>What is Salesforce AppExchange?</h3>
                <p>
                  {" "}
                  Salesforce AppExchange offers an extensive library of premium
                  and free apps, organised by industry and category. From
                  manufacturing and education to real estate, customer service,
                  and small business solutions, AppExchange provides
                  industry-specific tools to meet a wide range of business
                  challenges. However, while these apps cater to broad segments,
                  they often lack the level of customisation required by
                  individual enterprises. That’s where MetaQualt’s Salesforce
                  AppExchange development and consulting services step in. Why
                  settle for off-the-shelf when you can have bespoke? Partner
                  with MetaQualt to elevate your Salesforce experience with apps
                  designed exclusively for your enterprise. Let’s innovate
                  together—contact us today.
                </p>
              </div> */}

              <ConsultNowBtn />
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      {/* about-area-start */}

      {/* counter area start */}
      {/* <CounterHomeFive /> */}
      {/* counter area end */}

      {/* about-area-start */}
      <WeServes />
      {/* about-area-end */}

      {/* TechSlider-start */}
      <TechSlider />
      {/* TechSlider-start */}

      {/* rs-servicess-area-start */}
      {/* <div className="rs-services style8 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
      {/* rs-services-area-start */}

      {/* why choose us area start */}
      <WhyChoose />
      {/* why choose us area start */}

      {/* working-process-area-start */}

      {/* <div className="rs-process style2 pt-80 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}

      {/* working-process-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}
    </React.Fragment>
  );
};
export default SalesForce_Main;
