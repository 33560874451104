import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';



// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';
import Tableau from '../../components/Common/Breadcumb/Tableau';
import Tableau_Main from './Tableau_Main';


const Tableau_M = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
               
                <Tableau
                   pageTitle="Unlock Insights with MetaQualt’s Tableau Experts"
                   titleClass="page-title"
                   pageName="Tableau"
                   breadcrumbsImg={bannerbg}
                   animatedFont="lined-Font dance2"
                   animatedFontsize="Hire Tableau Experts" />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
               
                <Tableau_Main />
            
                {/* ServiceOneMain */}
            </div>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default Tableau_M;