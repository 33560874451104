import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

const Card = ({ question, answer, isOpen, setIsOpen }) => {
  const { text, bulletPoints } = answer;
  console.log(answer);

  return (
    <div className="faq">
      <div className="faq_qu" onClick={() => setIsOpen((isOpen) => !isOpen)}>
        <span>
          {question} {isOpen ? <FaMinus /> : <FaPlus />}
        </span>
        {isOpen && (
          <>
            <div className="faq_ans">
              {" "}
              <p>{text}</p>
             { bulletPoints &&  
                bulletPoints.map((list) => (<li>{list}</li>))
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
