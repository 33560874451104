import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg from "../../assets/img/banner/digitalmarket01.png";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";

// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/seo.png";
import imgMain41 from "../../assets/img/service/style2/main-img/social.png";
import imgMain43 from "../../assets/img/service/style2/main-img/content.png";
import imgMain28 from "../../assets/img/service/style2/main-img/media.png";
import imgMain53 from "../../assets/img/service/style2/main-img/app.png";
import imgMain42 from "../../assets/img/service/style2/main-img/content.png";

// Service Image Icons

import serviceIcon1 from "../../assets/img/service/style2/main-img/1.png";
import serviceIcon15 from "../../assets/img/service/style2/main-img/15.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";

import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import { CounterSection } from "../../components/CounterSection/Data";
import { Feature_Item } from "../../components/CounterSection/Data";


const digital_market = () => {
  let data = CounterSection.digitalMarket[0];
  const featureData = Feature_Item.digitalMarket;

  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60 sm-pb-0">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-6 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="Marketing Experts"
                titleClass="title pb-25"
                title="Advanced Digital Marketing Solutions

                                "
                descClass="desc pb-5"
                description="As a renowned digital marketing firm, Metaqualt gives clients the capability to achieve exceptional, even extraordinary digital presence through creative marketing skills and expert guidance. Using our tailored digital marketing solutions, we enable you to navigate through all of the tried methods for business growth and guide you toward outstanding results in the competitive world of cyberspace."
                secondDescClass="desc pb-16"
                secondDescription="Let's take your brand to the next level."
              />
              <ConsultNowBtn />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
        </div>
      </div>
      <CounterSectioin data={data} featureItemsData={featureData} />
      {/* rs-servicess-area-start */}
      <div className="rs-process style6 pt-80 pb-50 sm-pt-0">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Flexible Expertise"
            titleClass="title pb-10"
            title="Flexible, Dedicated Teams for Your Needs

                        "
            descClass="desc"
            description="Our agency is well-versed in developing impactful, result-driven digital marketing solutions that can heighten awareness, visibility, and engagement on all dimensions of channels..
                        "
            effectClass="heading-line"
          />

          <div className="rs-services style3 modify2 pt-20">
            <div className="row">
              <div className="col-lg-6  mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain40}
                  HoverImg={imgMain40}
                  Title="Search Engine Optimization (SEO)"
                  Text="Land at the top position on Google, Bing, and other search engines. Our SEO experts develop personalized strategies for optimizing your SERP position and organic traffic."
                  btnClass="d-none"
                />
              </div>

              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain28}
                  HoverImg={imgMain28}
                  Title="Social Media Advertising"
                  Text="Organic and paid campaigns are planned and executed to amplify your brand reach and engagement on social media platforms."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain41}
                  HoverImg={imgMain41}
                  Title="Pay-Per-Click (PPC) Advertisement"
                  Text="Obtain maximum return on investments in your business through targeted ad campaigns run by the specialized team on Google Ads, Facebook, and many others. Our campaign experts create high-performing campaigns that solve a variety of business problems.  "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain42}
                  HoverImg={imgMain42}
                  Title="Social Media Marketing (SMM)"
                  Text="build up the following through the creative team who curates all your social media posts and optimizes your social media accounts to get a better reach and conversion. 
                                    "
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain43}
                  HoverImg={imgMain43}
                  Title="Content Writing and Marketing"
                  Text="Our content specialists develop engaging blogs, articles, web content, and marketing copies using data-driven storytelling that attracts and converts audiences."
                  btnClass="d-none"
                />
              </div>
              <div className="col-lg-6 mb-30">
                <SingleServiceFive
                  itemClass="services-item light-purple-bg"
                  MainImg={imgMain53}
                  HoverImg={imgMain53}
                  Title="App Store Optimization (ASO)"
                  Text="We boost app visibility on the Google Play and Apple App Stores through expert ASO. We increase organic downloads and user engagement."
                  btnClass="d-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <Technologies />
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <div
        id="rs-service"
        className="rs-services main-home style6 pt-80 pb-50 dig_service"
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Dedicated Team"
            titleClass="title title"
            title="Flexible, Dedicated Teams for Your Needs "
            effectClass="heading-line"
            descClass="desc w-80"
            description="
                           We allocate specific teams to deal with all aspects of your digital marketing needs.
                            "
          />
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-30">
              <SingleService
                itemClass="services-item item-1"
                Title="SEO Team"
                Text={
                  <ul>
                    <li>SEO Experts</li>
                    <li>Content Writers</li>
                    <li>Creative Designers</li>
                    <li>PPC Team</li>
                  </ul>
                }
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-30">
              <SingleService
                itemClass="services-item item-2"
                Title="PPC Experts"
                Text={
                  <ul>
                    <li>Social Media Ads Specialists</li>
                    <li>Ads Copy Writers</li>
                    <li>Social Media Team</li>
                  </ul>
                }
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-30">
              <SingleService
                itemClass="services-item item-1"
                Title="Social Media Experts"
                Text={
                  <ul>
                    <li>Branding Specialists</li>
                    <li>Creative Designers</li>
                    <li>Content Team</li>
                  </ul>
                }
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-30">
              <SingleService
                itemClass="services-item item-2"
                Title="Content Writers"
                Text={
                  <ul>
                    <li>Copywriters</li>
                    <li>Content Strategists</li>
                  </ul>
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Industries That We Cater To"
                         Text="We tailor our marketing strategies for the following industries."
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
      {/* <Accordian data={accordions. digitl_market} /> */}
      {/* rs-services-area-start */}

      {/* newsletter-area-start */}

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["digital-marketing"]} />

          <AccordionCards data={accordions.digitl_market} />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-150 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Ready to Dominate the Digital Space?"
        ctaTextClass="exp-text"
        ctaText="Our focus is results, not just clicks. Change your business today with our expert-driven digital marketing strategy. 
                "
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="SCHEDULE A MEETING"
      />
      {/* newsletter-area-end */}

      {/* working-process-area-start */}
      {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
      {/* working-process-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}
    </React.Fragment>
  );
};

export default digital_market;
