import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';
import Application_Integration from '../../components/Common/Breadcumb/Application_Integration';
import ApplicationIntagration_Main from './ApplicationIntagration_Main';


const application_integration = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                <Application_Integration 
                 pageTitle="Application Integration Service"
                 titleClass="page-title "
                 pageName="Application Integration Services"
                 breadcrumbsImg={bannerbg}
                 animatedFont="lined-Font dance2"
                 animatedFontsize="Application Integration Service" />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                {/* < Application_migration_sec/>
                 */}
                 <ApplicationIntagration_Main />
                {/* ServiceOneMain */}
            </div>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default application_integration;