import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// // Service Image Icons
// import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
// import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
// import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
// import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
// import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
// import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="After implementing an app integration project with MetaQualt, your business will experience
"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Software Integration Services
                            "
                           
                            Text="Unlock your business's potential with MetaQualt’s software integration services, designed to seamlessly connect your business software, databases, and business intelligence systems. Our solutions ensure smoother operations, faster progress, and a unified approach to driving growth.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Business Application Integrations"
                            
                            Text="Empower your business with seamless communication between critical platforms such as content management systems, accounting, enterprise resource planning (ERP), customer relationship management (CRM), and business intelligence tools. With MetaQualt, you can streamline workflows and enhance efficiency across the board.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="API Integration Services
"
                           
                            Text="Leverage custom-built APIs to connect and integrate separate platforms and processes. Our API solutions add web service functionality to your applications, enabling synchronized data formats and smooth communication between diverse systems, ensuring everything works in harmony.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Service-Oriented Architecture"
                            
                           Text="MetaQualt specializes in developing enterprise-wide Service-Oriented Architectures (SOA), which are designed for system independence, rapid data retrieval, and maximum reusability. Our SOA solutions also ensure web service interoperability, simplifying your IT landscape and enabling flexibility across applications."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Reduced IT Complexity"
                           
                            Text="Break down the barriers of IT complexity and steep learning curves. Our integration services combine multiple functionalities into a single interface, making technology easier to use and navigate, so your team can focus on what matters most.

                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Increased Agility
"
                           Text="Stay ahead of the curve by responding quickly to market shifts and new opportunities. With MetaQualt’s enterprise application integration, you can adapt faster, align with business objectives, and increase your company’s agility in an ever-changing marketplace.
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;