import artificial_intelligence_sec from "../../pages/artificial-intelligence";

export const CounterSection = {
  digitalMarket: [
    {
      title: "Why Choose Metaqualt for Digital Marketing?",
      sub_text: "Experienced Professionals",
    },
  ],
  qualityEngineering: [
    {
      title: "Why Choose Metaqualt for Quality Engineering?",
      sub_text: "Experienced Professionals",
    },
  ],
  artificialintelligence: [
    {
      title: "Why Choose Metaqualt for Artificial Intelligence?",
      sub_text: "Experienced Professionals",
    },
  ],
  machinelearning: [
    {
      title: "Why Choose Metaqualt for Machine Learning?",
      sub_text: "Experienced Professionals",
    },
  ],
  dataScience: [
    {
      title: "Why Choose Metaqualt for Data Science?",
      sub_text: "Experienced Professionals",
    },
  ],
  softwareDevelopment: [
    {
      title: "Why Choose Metaqualt for Software Development?",
      sub_text: "Experienced Professionals",
    },
  ],
  CRMDevelopment: [
    {
      title: "Why Choose Metaqualt for CRM Development?",
      sub_text: "Experienced Professionals",
    },
  ],
  StartUpSolution: [
    {
      title: "Why Choose Metaqualt for StartUp Solution?",
      sub_text: "Experienced Professionals",
    },
  ],
  Power_BI: [
    {
      title: "Why Choose Metaqualt for your Power BI Projects?",
      sub_text: "Experienced Professionals",
    },
  ],
  Tableau: [
    {
      title: "Why Choose Metaqualt for your Tableau Projects?",
      sub_text: "Experienced Professionals",
    },
  ],
  Database_Management: [
    {
      title: "Why Choose Metaqualt for your database Management Projects?",
      sub_text: "Experienced Professionals",
    },
  ],
 
 
};

export const Feature_Item = {
  digitalMarket: [
    {
      number: "25+",
      title: "Experts in Digital Marketing",
    },
    {
      number: "13+",
      title: "Years in IT & Marketing",
    },
    {
      number: "1600+",
      title: "Trusted by Leading Brands",
    },
    {
      number: "100%",
      title: "NDA-Protected Contracts",
    },
  ],
  qualityEngineering: [
    {
      number: "150+",
      title: "Softwere Developers",
    },
    {
      number: "6+",
      title: "Years of Avg. Developer Experience",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Refund Policy",
    },
  ],
  artificialintelligence: [
    {
      number: "25+",
      title: "Expert AI Developers",
    },
    {
      number: "20+",
      title: "AI Projects Successfully Delivered",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Client-First Refund Policy",
    },
  ],
  machinelearning: [
    {
      number: "20+",
      title: "Experts in Machine Learning(ML)",
    },
    {
      number: "20+",
      title: "Successful Projects",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Refund Policy",
    },
  ],
  dataScience: [
    {
      number: "15+",
      title: "Data Scientists",
    },
    {
      number: "20+",
      title: " Projects Delivered",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Refund Policy",
    },
  ],
  softwareDevelopment: [
    {
      number: "150+",
      title: "Software Developers",
    },
    {
      number: "6+",
      title: "Years of Experience in Developer Years",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Refund Policy",
    },
  ],
  CRMDevelopment: [
    {
      number: "150+",
      title: "CRM Developers",
    },
    {
      number: "6+",
      title: "Years of CRM Development Experience",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Refund Policy",
    },
  ],
  StartupSolution: [
    {
      number: "150+",
      title: "App Developers",
    },
    {
      number: "6+",
      title: "Years of Average Developer Experience",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Refund Policy",
    },
  ],
  DatabaseManagement: [
    {
      number: "150+",
      title: "App Developers",
    },
    {
      number: "6+",
      title: "Years of Average Developer Experience",
    },
    {
      number: "90%",
      title: "Talent Retention Rate",
    },
    {
      number: "100%",
      title: "Refund Policy",
    },
  ],
};
