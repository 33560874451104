import React from "react";
import { CgLayoutGrid } from "react-icons/cg";
import { Link } from 'react-router-dom';

const SinglePost = ({
  blogImage,
  blogCategory,
  blogPublishedDate,
  blogTitle,
  blogDesc,
  blogButtonClass,
  id
}) => {

 const BlogDescription = blogDesc.length > 165 ? `${blogDesc.slice(0, 165)}...` : blogDesc;


  return (
    <div className="blog-item mb-44">
      <div className="image-wrap">
        <img src={blogImage} alt={blogTitle} />

        <ul className="post-categories">
          <li>
            <Link to="#">
              {blogCategory}
            </Link>
          </li>
        </ul>
      </div>
      <div className="blog-content">
        <ul className="blog-meta">
          <li className="date">
            <i className="fa fa-calendar-check-o"></i>{" "}
            {blogPublishedDate}
          </li>
          <li className="admin">
            <i className="fa fa-user-o"></i> {"admin"}
          </li>
        </ul>
        <h3 className="blog-title">
          {blogTitle}
        </h3>
        <p className="desc">
          {BlogDescription}
        </p>
        <div className={blogButtonClass}>
        <Link to={`/blog/blog-details/${id}`}>
            {"Continue Reading"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
