import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg from "../../assets/img/banner/Tableau_dev_img.png";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";

// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/t11.png";
import imgMain41 from "../../assets/img/service/style2/main-img/t2.png";
import imgMain42 from "../../assets/img/service/style2/main-img/t3.png";
import imgMain43 from "../../assets/img/service/style2/main-img/t5.png";
import imgMain28 from "../../assets/img/service/style2/main-img/t4.png";
import imgMainai16 from "../../assets/img/service/style2/main-img/t6.png";

// Service Image Icons
import experiencedai from "../../assets/img/service/style2/main-img/tablu1.png";
import experiencedai1 from "../../assets/img/service/style2/main-img/tablu2.png";
import experiencedai2 from "../../assets/img/service/style2/main-img/tablu3.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import { CounterSection } from "../../components/CounterSection/Data";

import { Feature_Item } from "../../components/CounterSection/Data";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";

const Tableau_Main = () => {
    let data = CounterSection.Tableau[0];
    const featureData = Feature_Item.artificialintelligence;
    return (
      <React.Fragment>
        {/* <!-- banner section start --> */}
        <div className="rs-about pt-150 pb-50 md-pt-60">
          <div className="container">
            <div className="row pb-0">
              <div className="col-lg-6 col-md-12 pl-20">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title2 mb-30"
                  subtitleClass="sub-text style-bg"
                  subtitle="AI Innovation Hub"
                  titleClass="title pb-25"
                  title="Elevate Your Business Strategy with MetaQualt's Tableau Consulting Services 
                                  "
                  descClass="desc pb-5"
                  description="Success depends on turning raw data into actionable insights in today's data-driven world. Tableau consulting services, a leading visual analytics platform, empower businesses to make smarter, faster decisions confidently.
Partner with MetaQualt Tableau consulting experts to harness the power of data visualization, real-time analytics, and custom Tableau solutions. Experience a smarter way to visualize data, analyze insights, and act on your findings. Let’s turn your enterprise data into your greatest asset."
                  secondDescClass="desc pb-16"
                />
                <ConsultNowBtn />
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="text-center">
                  <img className="main" src={bannerImg} alt="" />
                </div>
              </div>
            </div>
            <div className="shape-image">
              <img className="top dance" src={effectImg2} alt="" />
              <img className="bottom dance" src={effectImg3} alt="" />
            </div>
            {/* counter area start */}
            {/* <CounterHomeFive /> */}
            {/* counter area end */}
          </div>
        </div>
        {/* <!-- banner section end --> */}
  
        {/* rs-services-area-start */}
        {/* <div className="counter_sec pt-80 pb-20">
          <div className="container">
            <div class className="row">
              <div className="col-md-12">
              <SectionTitle
              sectionClass="sec-title2 text-center mb-30"
              subtitleClass="sub-text style-bg"
              subtitle="Experienced Professionals"
              titleClass="title pb-10"
              title="Why Choose Metaqualt for Artificial Intelligence?"
  
            />
              </div>
            </div>
            <div className="counter_inner text-center" >
            <div className="row">
              <div className="col-xl-3 col-lg-6">
                <div className="counter_inner_sec">
                  
                  <div className="listar-feature_listar-feature_sec2">
                  <div className="listar-feature_sec2">
                    <div className="listar-feature-icon-inner">
                      <h2>20+</h2>
                    </div>
                  </div>
                  <div className="counter_sec_des">
                    <h5>Experts in  Machine Learning</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="counter_inner_sec">
                 
                  <div className="listar-feature_listar-feature_sec2">
                  <div className="listar-feature_sec2">
                    <div className="listar-feature-icon-inner">
                      <h2>20+</h2>
                    </div>
                  
                  <div className="counter_sec_des">
                    <h5>Over Successful Projects</h5>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
              <div className="counter_inner_sec ">
                 
                 <div className="listar-feature_listar-feature_sec2">
                 <div className="listar-feature_sec2">
                   <div className="listar-feature-icon-inner">
                     <h2>20+</h2>
                   </div>
                 
                 <div className="counter_sec_des">
                   <h5>Over Successful Projects</h5>
                   </div>
                 </div>
               </div>
               </div>
              </div>
              <div className="col-xl-3 col-lg-6">
              <div className="counter_inner_sec">
                 
                 <div className="listar-feature_listar-feature_sec2">
                 <div className="listar-feature_sec2">
                   <div className="listar-feature-icon-inner">
                     <h2>20+</h2>
                   </div>
                 
                 <div className="counter_sec_des">
                   <h5>Over Successful Projects</h5>
                   </div>
                 </div>
               </div>
               </div>
              </div>
            </div>
            </div>
           
          </div>
  
        </div> */}
        <CounterSectioin data={data} featureItemsData={featureData} />
        {/* rs-services-area-start */}
  
        {/* rs-servicess-area-start */}
        <div className="rs-process style6 pt-80 pb-50">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title2 text-center mb-30"
              subtitleClass="sub-text style-bg"
              subtitle="Guiding Innovation"
              titleClass="title pb-10"
              title="Transform Your Data with Metaqualt's Tableau Analytics & Consulting Services"
              descClass="desc"
              description="At Metaqualt, we combine years of expertise with cutting-edge Tableau solutions to deliver powerful data insights. With a team of skilled data analytics professionals, we empower businesses to solve complex challenges, optimize performance, and drive growth through data-driven strategies. 
                          "
              effectClass="heading-line"
            />
  
            <div className="rs-services style3 modify2 pt-20">
              <div className="row">
                <div className="col-lg-6  mb-30">
                  <SingleServiceFive
                    itemClass="services-item light-purple-bg"
                    MainImg={imgMain40}
                    HoverImg={imgMain40}
                    Title="Tableau Data Analytics"
                    Text="Unlock deeper insights with visually compelling dashboards, advanced cohort analysis, and predictive analytics. Empower data scientists with R and Python integration for powerful quantitative analysis.
                                      "
                    btnClass="d-none"
                  />
                </div>
                <div className="col-lg-6 mb-30">
                  <SingleServiceFive
                    itemClass="services-item light-purple-bg"
                    MainImg={imgMain41}
                    HoverImg={imgMain41}
                    Title="Tableau Consulting Services"
                    Text="Build a tailored data roadmap with our expert consultants. We analyze your data ecosystem, address challenges, and deliver actionable insights.
                                      "
                    btnClass="d-none"
                  />
                </div>
                <div className="col-lg-6 mb-30">
                  <SingleServiceFive
                    itemClass="services-item light-purple-bg"
                    MainImg={imgMain42}
                    HoverImg={imgMain42}
                    Title="Tableau Business Intelligence Solutions"
                    Text=" Craft intuitive dashboards and interactive reports that turn raw data into meaningful stories. Simplify complex data with clear visual representations.
                                      "
                    btnClass="d-none"
                  />
                </div>
                <div className="col-lg-6 mb-30">
                  <SingleServiceFive
                    itemClass="services-item light-purple-bg"
                    MainImg={imgMain28}
                    HoverImg={imgMain28}
                    Title="Tableau Performance Tuning"
                    Text="Improve dashboard performance and optimize data workflows with our precision-driven performance tuning services."
                    btnClass="d-none"
                  />
                </div>
                <div className="col-lg-6 mb-30">
                  <SingleServiceFive
                    itemClass="services-item light-purple-bg"
                    MainImg={imgMain43}
                    HoverImg={imgMain43}
                    Title="Tableau Data Management"
                    Text=" Ensure seamless data preparation, governance, and automation across your analytics lifecycle with our end-to-end data management solutions.
                                      "
                    btnClass="d-none"
                  />
                </div>
                <div className="col-lg-6 mb-30">
                  <SingleServiceFive
                    itemClass="services-item light-purple-bg"
                    MainImg={imgMainai16}
                    HoverImg={imgMainai16}
                    Title="Tableau Data Visualization"
                    Text="Transform complex data into engaging visuals with charts, graphs, and maps. Tell impactful data stories that resonate with both technical and non-technical audiences.
                                      "
                    btnClass="d-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* rs-services-area-start */}
  
        {/* rs-services-area-start */}
        <Technologies />
        {/* rs-services-area-start */}
  
        {/* rs-services-area-start */}
        <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title2 text-center mb-46"
              subtitleClass="sub-text style-bg"
              subtitle="Process"
              titleClass="title title"
              title="Unlock Smarter Insights with Metaqualt's Tableau-Salesforce Integration

                              "
              effectClass="heading-line"
              descClass="desc w-80"
              description=" At Metaqualt, we empower businesses to harness data effectively. As a trusted Tableau consulting partner, we seamlessly integrate Tableau with Salesforce and your business data ecosystem, delivering a 360° customer view for smarter decisions.
"   />
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-30">
                <SingleService
                  itemClass="services-item"
                  serviceImage={experiencedai}
                  Title="Faster Decisions"
                  Text="Data-rich Salesforce dashboards deliver real-time insights, enabling quick, confident choices and improved efficiency across teams."
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <SingleService
                  itemClass="services-item"
                  serviceImage={experiencedai1}
                  Title="Salesforce CDP Integration"
                  Text="Salesforce CDP unifies customer data for precise segmentation, personalized engagement, and smarter strategies.
"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <SingleService
                  itemClass="services-item"
                  serviceImage={experiencedai2}
                  Title="Unified Visualization"
                  Text="Tableau dashboards integrated into Salesforce provide real-time insights within the CRM, streamlining workflows and enhancing collaboration for data-driven decisions."
                />
              </div>
            </div>
          </div>
        </div>
        {/* rs-services-area-start */}
  
        {/* rs-servicess-area-start */}
        {/* <div className="rs-services style8 pt-80 pb-50">
                  <div className="container">
                      <SectionTitle
                          sectionClass="sec-title2 text-center mb-44"
                          subtitleClass="sub-text gold-color"
                          subtitle="Work For Any Industry"
                          titleClass="title"
                          title="Best Solutions, For All Organizations"
                          effectClass="heading-line"
                      />
                      <div className="all-services">
                          
                          <SingleServiceFour
                              serviceIcon={img2}
                              Title="Fintech"
                              solutionURL="./fintech-app-solution" 
                          />
                          <SingleServiceFour
                              serviceIcon={img3}
                              Title="Healthcare" 
                              solutionURL="./healthcare-app-solution"
                          />
                         
                          <SingleServiceFour
                              serviceIcon={img13}
                              Title="E-Commerce" 
                              solutionURL="./ecommerce-solutions"
                          />
                          <SingleServiceFour
                              serviceIcon={img16}
                              Title="Fitness-Wellness" 
                              solutionURL="./fitness-wellness-app-solutions"
                          />
                          <SingleServiceFour
                              serviceIcon={img15}
                              Title="Real-Estate" 
                              solutionURL="./real-estate-app-solution"
                          />
                          <SingleServiceFour
                              serviceIcon={img17}
                              Title="Travel"
                              solutionURL="./travel-app-solutions" 
                          />
                          <SingleServiceFour
                              serviceIcon={img14}
                              Title="Buiness App"
                              solutionURL="./business-app-solution" 
                          />
                           <SingleServiceFour
                              serviceIcon={img11}
                              Title="Social Networking" 
                              solutionURL="./social-media-app"
                          />
                      </div>
                  </div>
              </div> */}
        {/* rs-services-area-start */}
  
        <div className="accordions pb-50">
          <div className="container">
            <FAQHeader data={faqDescriptions["Tableau"]} />
  
            <AccordionCards data={accordions.Tableau} />
          </div>
        </div>
  
        {/* newsletter-area-start */}
        <CTA
          ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
          ctaTitleClass="epx-title"
          ctaTitle="Enhance Your Business Strategy with Metaqualt’s Tableau Consulting Services."
          ctaTextClass="exp-text"
          ctaText="At Metaqualt, we turn data into a competitive advantage with seamless Tableau-Salesforce integration for real-time insights, AI-powered analytics via Einstein for predictive decisions, and customized dashboards for every department. Our data-rich dashboards drive confident decision-making, while CRM analytics embed insights into workflows. We focus on critical KPIs, provide deep customer insights through Salesforce CDP, and boost marketing impact with Datorama integration. Empower your team, streamline operations, and accelerate growth with Metaqualt's expertise."
          cta2TextClass="exp-text mannage"
          ctaButtonClass="readon learn-more"
          ctaButtonLink="#"
          ctaButtonText="Get In Touch"
        />
        {/* newsletter-area-end */}
  
        {/* working-process-area-start */}
        {/* <div className="rs-process style2 pt-80 pb-80">
                  <div className="container">
                      <SectionTitle
                          sectionClass="sec-title2 text-center mb-46"
                          subtitleClass="sub-text gold-color"
                          subtitle="Process"
                          titleClass="title title"
                          title="How we Works"
                          effectClass="heading-line" 
                          descClass="desc w-80"
                          description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                          
                      />
                      <div className="row">
                          <div className="col-lg-3 col-sm-6 md-mb-50">
                              <SingleProcess
                                  itemClass="addon-process"
                                  processImage={processImage1}
                                  titleClass="title"
                                  Title="Discover & Define"
                              />
                          </div>
                          <div className="col-lg-3 col-sm-6 md-mb-50">
                              <SingleProcess
                                  itemClass="addon-process"
                                  processImage={processImage13}
                                  titleClass="title"
                                  Title="Designing & Development"
                              />
                          </div>
                          <div className="col-lg-3 col-sm-6 sm-mb-50">
                              <SingleProcess
                                  itemClass="addon-process"
                                  processImage={processImage14}
                                  titleClass="title"
                                  Title="Testing & Deploying"
                              />
                          </div>
                          <div className="col-lg-3 col-sm-6">
                              <SingleProcess
                                  itemClass="addon-process"
                                  processImage={processImage15}
                                  titleClass="title"
                                  Title="Deliver & Maintenance"
                              />
                          </div>
                      </div>
                  </div>
              </div> */}
        {/* working-process-area-end */}
  
        {/* brand-area-start */}
        {/* <Brand /> */}
        {/* brand-area-end */}
      </React.Fragment>
    );
}

export default Tableau_Main