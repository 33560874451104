import React from 'react';
import { Link } from 'react-router-dom';
import Machinlearning from '../../../assets/img/banner/saasaaplication.png';

const SaasAppDev = (props) => {
    const { pageTitle, titleClass, parentCategory, pageCategory, pageName, breadcrumbsImg, animatedFont, animatedFontsize } = props;
	const breadcrumbsImgStyle = {
		backgroundImage: `url(${breadcrumbsImg})`
	}
  return (
    <div className="rs-breadcrumbs rs-rain-animate" style={breadcrumbsImgStyle}>
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-7 col-sm-12">
                <div className="breadcrumbs-inner pt-300 pb-250 md-pt-200 md-pb-150 sm-pt-180 sm-pb-50">
                    <h1 className={titleClass ? titleClass : 'page-title'}>{pageTitle ? pageTitle : 'Breadcrumbs'}</h1>
                    {/* <ul>
                        <li>
                            <Link to="/" className="active">{parentCategory ? parentCategory : 'Home'}</Link>
                        </li>
                        {pageCategory ? 
                            <>
                                <li>
                                    <Link to="/" className="active">{pageCategory ? pageCategory : 'Category'}</Link>
                                </li> 
                                <li>{pageName ? pageName : 'Page Name'}</li>
                            </> : <li>{pageName ? pageName : 'Page Name'}</li>							
                        }	
                    </ul> */}
                    <h2 className={animatedFont ? animatedFont : 'lined-Font'}>{animatedFontsize ? animatedFontsize : 'page-title'}</h2>
                </div>
            </div>
            <div className="col-lg-6 col-md-5 col-sm-12">
                <div className="text-center pt-250 pb-150 md-pt-200 md-pb-120 sm-pt-70 sm-pb-50">
                    <img className="bread_main_img" src={Machinlearning} alt=""/>
                </div>
            </div>
        </div>
        <div className="line-inner style2">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
        </div>
    </div>
</div>
  )
}

export default SaasAppDev