import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// // Service Image Icons
// import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
// import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
// import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
// import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
// import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
// import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Simplify Cloud Development"
                    titleClass="title testi-title text-white"
                    title="MetaQualt delivers affordable Azure & AWS solutions through a reliable offshore model."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Accelerate Your Time to Market with Microsoft Azure

                            "
                           
                            Text="Microsoft Azure takes care of the infrastructure and platforms, letting you focus solely on developing your business applications. This streamlined process speeds up development, helping you bring your solutions to market faster. At MetaQualt, we make it even easier with our expert Azure development services.
" />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Cut Costs with Microsoft Azure"
                            
                            Text="Microsoft Azure’s pay-as-you-go model helps you avoid hefty upfront expenses, letting you scale as your business grows. Ongoing costs are also lower than self-hosting, thanks to generous discounts from Microsoft. At MetaQualt, we help you maximize these benefits with our expert Azure development services.
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Boost Scalability with Microsoft Azure"
                           
                            Text="As your customer base grows, Microsoft Azure lets you seamlessly scale your application by adding capacity when needed. This ensures smooth performance without the worry of running out of server resources. At MetaQualt, we help you leverage Azure’s scalability for uninterrupted growth.
"/>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Unlock Creativity with Microsoft Azure"
                            
                           Text="Microsoft Azure enables rapid application deployment, allowing you to make changes quickly and adapt flexibly to evolving business needs. Its seamless process and adaptability make Azure the perfect partner for driving innovation and aligning with your business goals. At MetaQualt, we help you harness this potential for success.
"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Effortless Recovery with Microsoft Azure"
                           
                            Text="Microsoft Azure’s Site Recovery feature makes data recovery easy, ensuring your information is replicated and ready to be restored in the event of a disaster. Powered by Hyper-V Replica, it keeps virtual machines synchronized for smooth, reliable recovery. At MetaQualt, we ensure your business stays secure with Azure’s robust recovery solutions.
"
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Logistics and Transport Solutions for Smarter Operations"
                           Text="Looking to optimize costs, streamline workflows, or boost efficiency? Our transport and logistics web solutions use innovative technologies to meet your business needs. From fleet management to data-driven insights, we cover every aspect of logistics to help your operations run smoothly.
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;