import React from "react";

import FeatureItems from "./FeatureItems";
import SectionTitle from "../Common/SectionTitle";

const CounterSectioin = ({ data, featureItemsData }) => {
  return (
    <div className="counter_sec pt-80 pb-20">
      <div className="container">
        {/* <div className="sec-title2 text-center mb-0">
          <div className="sub-text style-bg">{data.sub_text}</div>
          <div className="title pb-10">{data.title}</div>
        </div> */}
        <div class className="row">
          <div className="col-md-12">
            <SectionTitle
              sectionClass="sec-title2 text-center mb-30"
              subtitleClass="sub-text style-bg"
              subtitle="Experienced Professionals"
              titleClass="title pb-10"
              title={data.title}
            />
          </div>
        </div>
        <div className="counter_inner text-center">
        <div className="row">
          <FeatureItems featureItemsData={featureItemsData} />
        </div>
        </div>
      
      </div>
    </div>
  );
};

export default CounterSectioin;
