import React from "react";

const List = ({ title, description }) => {
  
  return (
    <>
      <h6>{title}</h6>

      {description && (
        <ul className="dots">
          {description.map((li, index) => (
            
            <li key={index}>{li}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export default List;
