import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg from "../../assets/img/banner/robot.png";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";
import projectImg7 from '../../assets/img/bg/case-study.jpg';
// Service Icons
import imgMain60 from "../../assets/img/service/style2/main-img/m1.png";
import imgMain61 from "../../assets/img/service/style2/main-img/ai11.png";
import imgMain62 from "../../assets/img/service/style2/main-img/m3.png";
import imgMain63 from "../../assets/img/service/style2/main-img/m4.png";
import imgMain64 from "../../assets/img/service/style2/main-img/m5.png";
import imgMain65 from "../../assets/img/service/style2/main-img/m6.png";
import whychoosimg from "../../assets/img/banner/whychoosimg1.png";
import counerimg from "../../assets/img/banner/counterbg1.png";

// Service Image Icons
import serviceIcon41 from "../../assets/img/service/style2/main-img/mi1.png";
import serviceIcon42 from "../../assets/img/service/style2/main-img/mi2.png";
import serviceIcon43 from "../../assets/img/service/style2/main-img/mi3.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import { CounterSection } from "../../components/CounterSection/Data";
import { Feature_Item } from "../../components/CounterSection/Data";

const Machin_learning = () => {
  let data = CounterSection.machinelearning[0];
  const featureData = Feature_Item.machinelearning;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
            <div className="col-lg-7 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="We are Different"
                titleClass="title pb-25"
                title="Machine Learning Development Company

                                "
                descClass="desc pb-5"
                description="Bring your business to the next level with innovative ML solutions from Metaqualt
With Metaqualt, you can get custom machine learning development services tailored according to your business needs. Our expert in ML makes use of the latest technological advancements to facilitate innovation, insights you can act upon, and excellence through predictive analytics, NLP computer vision model evaluation, and custom ML solutions.
 "

              />
              <ConsultNowBtn />
            </div>
       
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
          {/* counter area start */}
          {/* <CounterSectioin data={data} featureItemsData={featureData} /> */}
          {/* <CounterHomeFive /> */}
          {/* counter area end */}
        </div>
      </div>
      < CounterSectioin data={data} featureItemsData={featureData}  />
      <div className="container-fluid whychoos mt-80 mb-50 pl-xxl-0 pl-xl-0 pl-xl-0" style={{ backgroundImage: `url(${projectImg7})` }}>
        <div className="row align-items-center">
          
          <div className="col-xxl-6 col-xl-5 p-0 m-0">
          <div className="whychoos_inner">
            <div className="whychoos-img"></div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-7 pt-50 pb-50">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            titleClass="title pb-10"
            title="Metaqualt: Your Trusted Partner in Machine Learning Excellence"

          />
            <div className="line_pure"></div>
            <div className="row">

              <div className="col-lg-6">
                <div className="line_left">
                  <h4 className="text-white">Superb Technical Expertise</h4>
                  <p className="desc">Our team of seasoned ML developers and data scientists develop innovative solutions tailored to your industry needs.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="line_rg">
                  <div className="br_top d-lg-none d-md-block"></div>
                  <h4 className="text-white">State-of-the-art Technology Stack</h4>
                  <p className="desc">Utilizing the most advanced tools, frameworks, and computing resources for robust and scalable ML models.</p>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="br_top"></div>
                <div className="line_left">
                  <h4 className="text-white">Personalized Cooperation</h4>
                  <p className="desc">Flexible engagement models and support that fit our efforts into your business goals.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="br_top"></div>
                <div className="line_rg">
                  <h4 className="text-white">Commitment to Quality and Timeliness</h4>
                  <p className="desc">Our agile approach ensures the timely delivery of quality results with full transparency and accountability.</p>
                </div>
              </div>

            </div>


          </div>
        </div>

      </div>


      <div className="rs-process style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Services"
                        titleClass="title pb-10"
                        title="Comprehensive Machine Learning Services"
                        descClass="desc"
                        description="Metaqualt designs and develops robust machine learning solutions based on your vision. From concept to deployment, our services are provided end-to-end with precision and care.
                        "
                        effectClass="heading-line"
                    />
                   
                   <div className="rs-services style3 modify2 pt-20">
                        <div className="row">
                            <div className="col-lg-6  mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain60}
                                    HoverImg={imgMain60}
                                    Title="Custom ML Model Development & Training"
                                    Text="Our team of specialists creates custom ML models with state-of-the-art frameworks, such as CNNs and RNNs. We start from data processing to hyperparameter tuning to come up with scalable solutions that are accurate in nature. 
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain61}
                                    HoverImg={imgMain61}
                                    Title="AI-Powered Solutions"
                                    Text="Our experts will help you build intelligent systems to automate processes, enhance workflows, and drive business innovation with the help of TensorFlow, PyTorch, or Apache Spark.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain62}
                                    HoverImg={imgMain62}
                                    Title="Feature Engineering"
                                    Text="Our features involved techniques like TF-IDF, word embeddings, and syntactic parsing in the creation of the features, capturing semantics and structure of the content by ensuring optimized model performance.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain63}
                                    HoverImg={imgMain63}
                                    Title="Predictive & Real-time Analytics"
                                    Text="We make your businesses predict trends through ARIMA, SARIMA, Autoencoders, and more algorithms and make proactive decisions with anomalies and data-driven insights."
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain64}
                                    HoverImg={imgMain64}
                                    Title="Natural Language Processing"
                                    Text="We will employ advanced NLP technologies, including Word2Vec, GloVe, BERT, and GPT, in order to provide machine translation, summarization, and text generation applications having an excellent user experience.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    MainImg={imgMain65}
                                    HoverImg={imgMain65}
                                    Title="Data Processing & Cleaning"
                                    Text="Our tools in data cleaning and structuring base are from technologies such as Pandas, NumPy, and Scikit-Learn, with advanced techniques of data wrangling and feature engineering. The approach to results will thus be accurate and reliable.
                                    "
                                    btnClass='d-none'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <Technologies />
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Search for the Best Machine Learning Solution? Our Experts are Ready."
            effectClass="heading-line"
            descClass="desc w-80"
            description="
                           Bring ML to optimize operations, predict trends, unlock data insights. Metaqualt brings you custom, high-quality ML services that best suit your needs.
                            "
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon41}
                Title="Data-Driven, Model-by-Model"
                Text=" All the models we construct are engineered with precision, from preprocessing to training and deployment. Our team guarantees accuracy in your machine learning solution along with scalability towards your future requirements, creating systems that mature with your data to deliver value continuously.
                                "
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon42}
                Title="Business-Centric Solutions for ML"
                Text="We work closely with you to understand what your business needs and objectives are; solutions will be delivered that would drive growth, increase efficiencies, and solve real-world challenges in alignment with the strategic goals that the machine learning model will have for you. "
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon43}
                Title="Future-Ready ML Models"
                Text="Metaqualt ensures your ML solution is future-proof by the team of experts designing intelligent systems that adapt to the unfolding future demands, which will keep your business competitive and effective in this rapidly changing landscape. 
                                "
              />
            </div>
          </div>
        </div>
      </div>
      {/* rs-services-area-start */}

      {/* rs-servicess-area-start */}
      {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
      {/* rs-services-area-start */}

      {/* newsletter-area-start */}

      <div className="accordions pb-50">
        <div className="container">
          <FAQHeader data={faqDescriptions["machine-learning"]} />

          <AccordionCards data={accordions.machine_learning} />
        </div>
      </div>

      <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Hire our UI/UX Designers and witness a huge transformation"
        ctaTextClass="exp-text"
        ctaText="Let your ideas traverse the right stream of brainstorming, branding, web interface, and logo design to turn them into irresistible web apps. With our UI/UX masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision.  "
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      />
      {/* newsletter-area-end */}

      {/* working-process-area-start */}
      {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
      {/* working-process-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}
    </React.Fragment>
  );
};

export default Machin_learning;
