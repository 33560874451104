import React from "react";
import { useParams } from "react-router-dom";
import { data } from "./data";
import Description from "./Description";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import aboutImage1 from '../../assets/img/about/hiteshsir.png';
import aboutImage2 from '../../assets/img/about/gautamsir.png';
import Footer from "../../components/Layout/Footer";
const WhitePaperDetail = () => {

  const { whitepaperId } = useParams();

  const post = data.find((p) => p.id === Number(whitepaperId));
  console.log(post);



  return (
    <div>
       <HeaderStyleThree parentMenu="blog" activeMenu="/blog-details" />
      <section>
        <div classname="heading_sec">
        <div className="container mt-80">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h2 className="pt-150">{post.title}</h2>
            </div>
          </div>
        </div>
        </div>
      </section>
      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3 className="mt-5">About the Publishers</h3>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-12">
              <h3>Hitesh Thakkar (Author):</h3>
              <p>
                CEO of Metaqualt Software, with a focus on blockchain
                technology, Fintech solutions, and offshore IT staff
                augmentation. Hitesh has led Metaqualt to provide cutting-edge
                blockchain solutions to various industries. Connect with Hitesh
                on LinkedIn.
              </p>
            </div>
            <div className="col-lg-3 col-md-12">
            <img src={aboutImage1} alt="About Section" />
    </div>
          </div>
        </div>
        <div className="container mt-30">
          <div className="row align-items-center">
          <div className="col-lg-3 col-md-12">
            <img src={aboutImage2} alt="About Section" />
            </div>
            <div className="col-lg-9 col-md-12">
           
              <h3>Gautam Sharma (Editor):</h3>
              <p>
                Director at Groovy Orange Consulting and Fractional Director at
                Iconflux Technologies. With over 15 years of experience in GTM
                strategies, branding, and IT consulting, Gautam specializes in
                strategic solutions for blockchain and digital transformation.
                Connect with Gautam on LinkedIn.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div classname="about_meta">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3 className="mt-50">About MetaQualt</h3>
            </div>
          </div>
        </div>
     
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h3>
                MetaQualt Software: Simplifying Fintech and Deep-tech for
                Technology Leaders
              </h3>
              <ul>
                <li>
                  MetaQualt is a global IT solutions provider from India,
                  specializing in fintech, blockchain, cryptocurrency, and
                  offshore staff augmentation.
                </li>
                <li>
                  We focus on innovative solutions that drive business success
                  for our clients.
                </li>
                <li>
                  <h4 className="mb-2 mt-3">Vision:</h4>
                  <p>To empower businesses with over a decade of deep tech and
                  domain expertise.</p>
                </li>

                <li>
                  <h4 className="mb-2 mt-3">Mision:</h4>
                  <p>Building a sustainable future through technology & reliable
                  partnerships.</p>
                </li>
              </ul>
             
            </div>
          </div>
        </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {/* <h1>{post.subtitle}</h1> */}
              {post.introTitle && <h2>{post.introTitle}</h2>}
              <p>{post.Desc}</p>
            </div>
          </div>
        </div>
        <div className="container pb-150">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {post.sections &&
                post.sections.map((desc, index) => (
                  <Description key={index} {...desc} />
                ))}
            </div>
          </div>
        </div>

        {post.conclusion && (
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <h2>{post.conclusion.title}</h2>

                <p>{post.conclusion.summary}</p>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
};

export default WhitePaperDetail;
