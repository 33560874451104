import React, { Component, useState } from "react";
import SearchWidget from "../Widget/SearchWidget";
import RecentPostWidget from "../Widget/RecentPostWidget";
import CategoriesWidget from "../Widget/CategoriesWidget";
import SinglePost from "./SinglePost";

import { data } from "./data";

const WhitePaperMain = () => {
  const [posts, setPosts] = useState(data);


  return (
    <div className="rs-blog rs-inner-blog pt-120 pb-120 whitepaper">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4 col-md-12 order-last">
            <div className="widget-area">
              <SearchWidget />
              <RecentPostWidget />
              <CategoriesWidget />
            </div>
          </div> */}
          <div className="col-lg-12 pr-34 md-pr-14">
          <div className="widget-area_whitepaper">
            {posts.map((post) => (
              <SinglePost {...post} />
            ))}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhitePaperMain;
