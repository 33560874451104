import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// // Service Image Icons
// import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
// import mainIcon65 from '../../assets/img/service/style2/main-img/65.png';
// import mainIcon34 from '../../assets/img/service/style2/main-img/34.png';
// import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
// import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
// import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';

// Service Image Icons
import mainIconM1 from '../../assets/img/service/M1.png';
import mainIconM2 from '../../assets/img/service/M2.png';
import mainIconM3 from '../../assets/img/service/M3.png';
import mainIconM4 from '../../assets/img/service/M4.png';
import mainIconM5 from '../../assets/img/service/M5.png';
import mainIconM6 from '../../assets/img/service/M6.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg18 style3 pt-80 pb-80">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text text-white"
                    subtitle="Why Hire Developers?"
                    titleClass="title testi-title text-white"
                    title="Why Choose MetaQualt for Custom Application Migration Services?"
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIconM1}
                            hoverIcon={mainIconM1}
                            Title="Skilled Experts, Exceptional Results
                            "
                           
                            Text="Our certified professionals continually upskill to stay ahead of the curve. This ensures you have access to top-tier specialists capable of handling complex legacy application migrations, delivering quality-driven solutions tailored to your needs.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="02"
                            mainIcon={mainIconM2}
                            hoverIcon={mainIconM2}
                            Title="Tailored Migration Strategies"
                            
                            Text="We know every business has unique needs. As leaders in enterprise application migration, we provide a thorough assessment to recommend solutions that align with your goals. From risk analysis to platform selection, our 360-degree consulting ensures a smooth transition.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIconM3}
                            hoverIcon={mainIconM3}
                            Title="Decades of Expertise"
                           
                            Text="With over a decade of experience, MetaQualt has earned a reputation for creating custom migration solutions that align with your vision. Whether your project is simple or complex, our experts craft strategies that streamline the process while maintaining cost efficiency.
                            "
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="04"
                            mainIcon={mainIconM4}
                            hoverIcon={mainIconM4}
                            Title="Future-Ready Scalability"
                            
                           Text="Our migration solutions are designed to grow with you. We assess your scaling needs and create flexible architectures that accommodate future trends, ensuring your IT systems remain secure and adaptable."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIconM5}
                            hoverIcon={mainIconM5}
                            Title="Clear Strategy, Detailed Documentation"
                           
                            Text="We ensure clarity and transparency by providing detailed documentation outlining the migration strategy, risks, fallback plans, and future-proofing measures. This roadmap keeps everything aligned throughout the project.

                            "
                        />
                    </div>
                   
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="06"
                            mainIcon={mainIconM6}
                            hoverIcon={mainIconM6}
                            Title="Complete Transparency
"
                           Text="At MetaQualt, we prioritize trust. We offer 100% transparency at every stage, keeping you informed and in control throughout the migration process.
Choose MetaQualt for reliable, scalable, and expertly executed custom app migration services. Let’s shape the future of your business together.
                           "
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default WhyChoose;