import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import Brand from "../../components/Common/Brand";
import bannerImg31 from "../../assets/img/banner/cloudcon.jpg";
import SingleProcess from "../../components/Process/SingleProcess";
import Technologies from "./Technologies";
import SingleServiceFour from "../../components/Service/SingleServiceFour";
import CTA from "../../components/Common/CTA";
import SingleServiceFive from "../../components/Service/SingleServiceFive";
import SingleService from "../../components/Service/SingleService";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
// Working Process Images
import processImage1 from "../../assets/img/process/1.png";
import processImage13 from "../../assets/img/process/13.png";
import processImage14 from "../../assets/img/process/14.png";
import processImage15 from "../../assets/img/process/15.png";
import projectImg7 from "../../assets/img/bg/case-study.jpg";
// Service Icons
import imgMain40 from "../../assets/img/service/style2/main-img/40.png";
import imgMain41 from "../../assets/img/service/style2/main-img/41.png";
import imgMain42 from "../../assets/img/service/style2/main-img/42.png";
import imgMain43 from "../../assets/img/service/style2/main-img/43.png";
import imgMain28 from "../../assets/img/service/style2/main-img/28.png";
import whychoosimg from "../../assets/img/banner/whychoosimg1.png";
import counerimg from "../../assets/img/banner/counterbg1.png";

// Service Image Icons
import serviceIcon14 from "../../assets/img/service/style2/main-img/14.png";
import serviceIcon1 from "../../assets/img/service/style2/main-img/1.png";
import serviceIcon15 from "../../assets/img/service/style2/main-img/15.png";

// Service Icons
import img2 from "../../assets/img/technology/style3/2.png";
import img3 from "../../assets/img/technology/style3/3.png";
import img11 from "../../assets/img/technology/style3/11.png";
import img13 from "../../assets/img/technology/style3/13.png";
import img16 from "../../assets/img/technology/style3/16.png";
import img15 from "../../assets/img/technology/style3/15.png";
import img14 from "../../assets/img/technology/style3/14.png";
import img17 from "../../assets/img/technology/style3/17.png";

import techImg34 from "../../assets/img/technology/style2/34.svg";
import techImg35 from "../../assets/img/technology/style2/35.svg";
import techImg36 from "../../assets/img/technology/style2/36.svg";
import techImg37 from "../../assets/img/technology/style2/37.svg";
import techImg38 from "../../assets/img/technology/style2/38.svg";
import techImg9 from "../../assets/img/technology/style2/9.svg";
import techImg8 from "../../assets/img/technology/style2/8.svg";
import techImg4 from "../../assets/img/technology/style2/4.svg";
import techImg3 from "../../assets/img/technology/style2/3.svg";

import effectImg2 from "../../assets/img/about/dotted-3.png";
import effectImg3 from "../../assets/img/about/shape3.png";
import ConsultNowBtn from "../../components/ConsultNowBtn";
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data";
import CounterSectioin from "../../components/CounterSection/CounterSectioin";
import { CounterSection } from "../../components/CounterSection/Data";
import { Feature_Item } from "../../components/CounterSection/Data";
import OurServices from "./weProvide";
import WhyChoose from "./whychooseus";
import mean_stack31 from "../../assets/img/about/clousconst3.png";



const cloud_consulting = () => {
  let data = CounterSection.machinelearning[0];
  const featureData = Feature_Item.machinelearning;
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div className="rs-about pt-150 pb-50 md-pt-60">
        <div className="container">
          <div className="row pb-0">
          <div className="col-lg-5 col-md-12">
              <div className="text-center">
                <img className="main" src={bannerImg31} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 pl-20">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title2 mb-30"
                subtitleClass="sub-text style-bg"
                subtitle="We are Different"
                titleClass="title pb-25"
                title="Why Amazon and Azure Cloud Development with MetaQualt?"
                descClass="desc pb-5"
                description="MetaQualt is a leading provider of AWS cloud services, helping businesses unlock the full potential of AWS cloud hosting through a robust framework of managed services. Our team of seasoned experts has years of experience within the AWS ecosystem, offering solutions ranging from AWS-based community management to storage and administration.
                We also boast a skilled team of Microsoft developers with extensive experience in the Azure platform. Whether you need Azure development, administration, or cloud solutions, MetaQualt is your go-to partner. Our mission is simple: to help your business grow by delivering reliable, scalable, and tailored cloud solutions for every project.
 "
              />
              <ConsultNowBtn />
            </div>

          
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg2} alt="" />
            <img className="bottom dance" src={effectImg3} alt="" />
          </div>
          {/* counter area start */}
          {/* <CounterSectioin data={data} featureItemsData={featureData} /> */}
          {/* <CounterHomeFive /> */}
          {/* counter area end */}
        </div>
      </div>

      <div className="style3 pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container relative">
          <div className="sec-left">
            <h2 className="title"> What We Provide</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="row align-items-left">
                <div className="col-lg-12 col-md-12">
                  <SectionTitle
                    sectionClass="sec-title2 text-left mb-30"
                    subtitleClass="sub-text "
                    subtitle=""
                    titleClass="title pb-10"
                    title="MetaQualt: Transforming Businesses with Cloud Solutions

                                        "
                    descClass="desc"
                    description="Cloud computing has become essential for enterprises and SMBs, enabling them to securely store critical data while maintaining confidentiality. AWS has revolutionized the perception of the cloud, breaking barriers and offering businesses a cost-effective, efficient way to launch applications online.
At MetaQualt, we deliver top-notch cloud development services tailored to your needs. With our dedicated offshore developers and reliable RelyShore model, we ensure scalable, secure, and efficient solutions to drive your business forward.

                                        "
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                    <li className="">
                    Cloud assessment consulting
                    </li>
                    <li className="">
                    Maintenance and support services
                    </li>
                    <li className="">
                    Developing cloud applications
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                  <ul className="check-square">
                   
                    
                   
                    <li className="">
                    Cloud application migration
                    </li>
                    <li className="">
                    Integrating cloud applications
                    </li>
                  </ul>
                </div>
           
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="text-center relative">
                <img className="main" src={mean_stack31} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OurServices />
      {/* rs-services-area-start */}

        {/* why choose us area start */}
        <WhyChoose />


      {/* why choose us area start */}

      <div className="accordions pb-50 pt-80">
        <div className="container">
          <FAQHeader data={faqDescriptions["Cloud_Consulting"]} />

          <AccordionCards data={accordions.Cloud_Consulting} />
        </div>
      </div>

      {/* rs-services-area-start */}
      {/* <Technologies /> */}
      {/* rs-services-area-start */}

      {/* rs-services-area-start */}
      {/* <div id="rs-service" className="rs-services main-home style6 pt-80 pb-50">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title title"
            title="Search for the Best Machine Learning Solution? Our Experts are Ready."
            effectClass="heading-line"
            descClass="desc w-80"
            description="
                           Bring ML to optimize operations, predict trends, unlock data insights. Metaqualt brings you custom, high-quality ML services that best suit your needs.
                            "
          />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon14}
                Title="Data-Driven, Model-by-Model"
                Text=" All the models we construct are engineered with precision, from preprocessing to training and deployment. Our team guarantees accuracy in your machine learning solution along with scalability towards your future requirements, creating systems that mature with your data to deliver value continuously.
                                "
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon1}
                Title="Business-Centric Solutions for ML"
                Text="We work closely with you to understand what your business needs and objectives are; solutions will be delivered that would drive growth, increase efficiencies, and solve real-world challenges in alignment with the strategic goals that the machine learning model will have for you. "
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleService
                itemClass="services-item"
                serviceImage={serviceIcon15}
                Title="Future-Ready ML Models"
                Text="Metaqualt ensures your ML solution is future-proof by the team of experts designing intelligent systems that adapt to the unfolding future demands, which will keep your business competitive and effective in this rapidly changing landscape. 
                                "
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* rs-services-area-start */}

      {/* rs-servicess-area-start */}
      {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
      {/* rs-services-area-start */}

      {/* newsletter-area-start */}

      {/* <CTA
        ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
        ctaTitleClass="epx-title"
        ctaTitle="Hire our UI/UX Designers and witness a huge transformation"
        ctaTextClass="exp-text"
        ctaText="Let your ideas traverse the right stream of brainstorming, branding, web interface, and logo design to turn them into irresistible web apps. With our UI/UX masterminds, you always get an immaculate solution that is worth every dime, generates leads like crazy, and materializes your vision.  "
        ctaButtonClass="readon learn-more"
        ctaButtonLink="#"
        ctaButtonText="Get In Touch"
      /> */}
      {/* newsletter-area-end */}

      {/* working-process-area-start */}
      {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
      {/* working-process-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}
    </React.Fragment>
  );
};

export default cloud_consulting;
