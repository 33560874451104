import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle/SectionTitle';

// Testimonial Avatars
import aboutImg1 from '../../assets/img/about/SalesforceConsultingRooted.png';

const TechSlider = () => {
    return (
        <React.Fragment>
            <div className="bg18 provide_sec style3 pt-100 pb-100 md-pt-80 md-pb-80" >
                <div className="container relative">
                <div className="sec-left">
                    <h2 className="title text-white">What's We Provide</h2>
                </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12 md-mb-50'>
                            <div className="image-part">
                                <img
                                    src={aboutImg1}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='row align-items-left'>
                                <div className='col-lg-12 col-md-12'>
                                <SectionTitle
                                    sectionClass="sec-title2 text-left mb-30"
                                    subtitleClass="sub-text gold-color"
                                    subtitle="Our Top Features "
                                    titleClass="title pb-10 text-white"
                                    title="Revolutionise Your CRM with MetaQualt’s Salesforce Lightning Solutions"
                                    descClass="desc text-white"
                                    description={
                                        
                                        <>
                            From&nbsp;
                            <strong>Salesforce Lightning</strong>
                            &nbsp;is more than a CRM—it's a faster, smarter platform designed to enhance productivity for developers, admins, business users, and IT teams alike. At  &nbsp;
                            <strong>MetaQualt</strong>a leading &nbsp;<strong>Salesforce development company,</strong>we specialise in creating tailored Lightning migration strategies that deliver exceptional results.
                                     </>
                                    }

                        
                                />
                                
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                    <h5 className="text-white">Our Salesforce Lightning solutions focus on: </h5>
                                    <ul className="check-square">
                                        <li className="text-white">Boosting productivity across teams.</li>
                                        <li className="text-white">Enabling smarter, more efficient operations.</li>
                                        <li className="text-white">Enhancing user experiences with intuitive design and functionality.</li>
                                        <li className="text-white">Saving time with custom features and automated workflows.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default TechSlider;