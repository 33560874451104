import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import Brand from '../../components/Common/Brand';
import socialmediait from '../../assets/img/banner/socialmediait.png';
import SingleProcess from '../../components/Process/SingleProcess';
import Technologies from './Technologies';
import SingleServiceFour from '../../components/Service/SingleServiceFour';
import CTA from '../../components/Common/CTA';
import SingleServiceFive from '../../components/Service/SingleServiceFive';
import SingleService from '../../components/Service/SingleService';
import CounterHomeFive from '../../components/Elements/Counter/CounterHomeFive';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage13 from '../../assets/img/process/13.png';
import processImage14 from '../../assets/img/process/14.png';
import processImage15 from '../../assets/img/process/15.png';

// Service Icons
import imgMain40 from '../../assets/img/service/style2/main-img/40.png';
import imgMain41 from '../../assets/img/service/style2/main-img/41.png';
import imgMain42 from '../../assets/img/service/style2/main-img/42.png';
import imgMain43 from '../../assets/img/service/style2/main-img/43.png';
import imgMain28 from '../../assets/img/service/style2/main-img/28.png';

// Service Image Icons
import ItemIcon1 from '../../assets/img/service/style2/main-img/39.png';
import ItemIcon2 from '../../assets/img/service/style2/main-img/35.png';
import ItemIcon3 from '../../assets/img/service/style2/main-img/5.png';

// Service Icons
import img2 from '../../assets/img/technology/style3/2.png';
import img3 from '../../assets/img/technology/style3/3.png';
import img11 from '../../assets/img/technology/style3/11.png';
import img13 from '../../assets/img/technology/style3/13.png';
import img16 from '../../assets/img/technology/style3/16.png';
import img15 from '../../assets/img/technology/style3/15.png';
import img14 from '../../assets/img/technology/style3/14.png';
import img17 from '../../assets/img/technology/style3/17.png';

import techImg34 from '../../assets/img/technology/style2/34.svg';
import techImg35 from '../../assets/img/technology/style2/35.svg';
import techImg36 from '../../assets/img/technology/style2/36.svg';
import techImg37 from '../../assets/img/technology/style2/37.svg';
import techImg38 from '../../assets/img/technology/style2/38.svg';
import techImg9 from '../../assets/img/technology/style2/9.svg';
import techImg8 from '../../assets/img/technology/style2/8.svg';
import techImg4 from '../../assets/img/technology/style2/4.svg';
import techImg3 from '../../assets/img/technology/style2/3.svg';

import { Link } from 'react-router-dom';
import effectImg2 from '../../assets/img/about/dotted-3.png';
import effectImg3 from '../../assets/img/about/shape3.png';
import Accordian from '../digitl-market/Accordian';
import { accordions } from "../../components/Accordian/Data";
import AccordionCards from "../../components/Accordian/AccordianCards";
import { faqDescriptions } from "../../components/Accordian/Data"
import FAQHeader from "../../components/FAQHeader/FAQHeader";
import ConsultNowBtn from '../../components/ConsultNowBtn';
import { CounterSection } from '../../components/CounterSection/Data';
import CounterSectioin from '../../components/CounterSection/CounterSectioin';
import { Feature_Item } from "../../components/CounterSection/Data";



const Quality_Engineering = () => {
    let data = CounterSection. qualityEngineering[0];
    const featureData = Feature_Item.qualityEngineering;
    return (
        <React.Fragment>

            {/* <!-- banner section start --> */}
            <div className="rs-about pt-150 pb-50 md-pt-60 sm-pb-0">
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-lg-6 col-md-12 pl-20">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title2 mb-30"
                                subtitleClass="sub-text style-bg"
                                subtitle="We are Different"
                                titleClass="title pb-25"
                                title="End-to-End Software Testing and QA Services

                                "
                                descClass="desc pb-5"
                                description="EYour product deserves nothing less than perfection! Tap into Metaqualt's thorough quality engineering services to maximize the reliability, performance, and overall quality of your business-orientated software solutions. With our meticulous testing methods and precision-driven strategies, Metaqualt can raise your product to unmatched standards."
                                secondDescClass="desc pb-16"
                                secondDescription="Complete software testing and QA services to ensure seamless performance, reliability, and quality at every development stage. "

                            />
                            <ConsultNowBtn />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="text-center">
                                <img className="main" src={socialmediait} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg2} alt="" />
                        <img className="bottom dance" src={effectImg3} alt="" />
                    </div>
                    {/* counter area start */}
                    {/* <CounterHomeFive /> */}
                    {/* counter area end */}
                </div>
            </div>
    
            {/* < CounterSectioin data={data} featureItemsData={featureData}  /> */}
            {/* <!-- banner section end --> */}

            {/* rs-services-area-start */}
            {/* <div className="rs-technology pt-30 pb-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1 col-md-4 col-sm-6 col-12"></div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg34} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>  
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg35} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>  
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg36} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div> 
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg37} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div> 
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg38} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-4 col-sm-6 col-12"></div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg8} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div> 
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg9} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg4} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>   
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="technology-item">
                                <a href="#">
                                    <div className="logo-img">
                                        <img src={techImg3} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>                                  
                    </div>
                </div>
            </div> */}

            {/* <div className="counter_sec pt-80 pb-20">
        <div className="container">
          <div class className="row">
            <div className="col-md-12">
            <SectionTitle
            sectionClass="sec-title2 text-center mb-30"
            subtitleClass="sub-text style-bg"
            subtitle="Experienced Professionals"
            titleClass="title pb-10"
            title="Why Choose Metaqualt for Quality Engineering?"

          />
            </div>
          </div>
          <div className="counter_inner text-center" >
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <div className="counter_inner_sec">
                
                <div className="listar-feature_listar-feature_sec2">
                <div className="listar-feature_sec2">
                  <div className="listar-feature-icon-inner">
                    <h2>20+</h2>
                  </div>
                </div>
                <div className="counter_sec_des">
                  <h5>Experts in  Machine Learning</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="counter_inner_sec">
               
                <div className="listar-feature_listar-feature_sec2">
                <div className="listar-feature_sec2">
                  <div className="listar-feature-icon-inner">
                    <h2>20+</h2>
                  </div>
                
                <div className="counter_sec_des">
                  <h5>Over Successful Projects</h5>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
            <div className="counter_inner_sec ">
               
               <div className="listar-feature_listar-feature_sec2">
               <div className="listar-feature_sec2">
                 <div className="listar-feature-icon-inner">
                   <h2>20+</h2>
                 </div>
               
               <div className="counter_sec_des">
                 <h5>Over Successful Projects</h5>
                 </div>
               </div>
             </div>
             </div>
            </div>
            <div className="col-xl-3 col-lg-6">
            <div className="counter_inner_sec">
               
               <div className="listar-feature_listar-feature_sec2">
               <div className="listar-feature_sec2">
                 <div className="listar-feature-icon-inner">
                   <h2>20+</h2>
                 </div>
               
               <div className="counter_sec_des">
                 <h5>Over Successful Projects</h5>
                 </div>
               </div>
             </div>
             </div>
            </div>
          </div>
          </div>
         
        </div>

      </div> */}

            {/* rs-servicess-area-start */}
            <div className="rs-process style6 pt-80 pb-50 sm-pt-0">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg"
                        subtitle="Our Quality Engineering Services"
                        titleClass="title pb-10"
                        title="Excellence in Quality Engineering with Metaqualt



                        "
                        descClass="desc"
                        description="Assuring quality is our commitment! Metaqualt transforms your digital products into flawless user experiences through best industry practices, cutting-edge tools, and deep expertise
                        "
                        effectClass="heading-line"
                    />

                    <div className="rs-services style3 modify2 pt-20 qu-service">
                        <div className="row">
                            <div className="col-lg-4 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    Title="High-load System Strategy
"
                                    Text={
                                        <ul>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Scalability Strategy</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Performance Audit</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Upgrade Recommendations</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Continuous Evaluation</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Reports & Documentation</li>
                                        </ul>
                                    }
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    Title="End-to-End Quality Engineering"
                                    Text={
                                        <ul>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Functional Testing</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Non-Functional Testing</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Endurance Testing</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Crash Testing</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Testing Automation</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Customized Testing Scenarios</li>
                                        </ul>
                                    }
                                    btnClass='d-none'
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <SingleServiceFive
                                    itemClass="services-item light-purple-bg"
                                    Title="Performance Enhancement"
                                    Text={
                                        <ul>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Bottleneck Analysis</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Performance Optimization Assessment</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Improvement Strategies</li>
                                            <li><span><i class="fa-regular fa-circle-check pr-5 pl-5 ml-5"></i></span>Implementation of Optimized Solutions</li>
                                        </ul>
                                    }
                                    btnClass='d-none'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* rs-services-area-start */}

            {/* rs-services-area-start */}
            <Technologies />
            {/* rs-services-area-start */}

            {/* rs-services-area-start */}
            <div id="rs-service quilty_service" className="rs-services main-home style6 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="Process"
                        titleClass="title title"
                        title="Looking for Top-Notch Quality Engineering Services? Our Experts Are Ready. 
                            "
                        effectClass="heading-line"
                        descClass="desc w-80"
                        description="We ensure your software delivers exceptional performance and reliability. If you’re seeking rigorous testing and seamless optimization, you’ve found the right team to make your software flawless. 
                            "
                    />
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService
                                itemClass="services-item"
                                serviceImage={ItemIcon1}
                                Title="Detail-by-Detail, Test-by-Test"
                                Text="Our detailed scrutiny by our vigilant engineers checks out each line of your code. Our differential analysis finds problems and thus ensures that the produced software operates perfectly in any conditions. Let our experts transform your product into a masterpiece of reliability."

                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService
                                itemClass="services-item"
                                serviceImage={ItemIcon2}
                                Title="User-Centric Quality Assurance"
                                Text="While maintaining the level of accuracy in the test, we make sure that our software is deployed to work flawlessly, meeting every expectation in the book."
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <SingleService
                                itemClass="services-item"
                                serviceImage={ItemIcon3}
                                Title="Future-Proof Optimization"
                                Text="Our engineers look at future-proofing the application to face a technological landscape that is always in motion and optimizes for performance and adaptation. Expect an end solution that not only works but is also going to last in terms of efficiency. 
                                "
                            />
                        </div>

                    </div>
                </div>
            </div>
            {/* rs-services-area-start */}

            {/* rs-servicess-area-start */}
            {/* <div className="rs-services style8 pt-80 pb-50">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-44"
                        subtitleClass="sub-text gold-color"
                        subtitle="Work For Any Industry"
                        titleClass="title"
                        title="Best Solutions, For All Organizations"
                        effectClass="heading-line"
                    />
                    <div className="all-services">
                        
                        <SingleServiceFour
                            serviceIcon={img2}
                            Title="Fintech"
                            solutionURL="./fintech-app-solution" 
                        />
                        <SingleServiceFour
                            serviceIcon={img3}
                            Title="Healthcare" 
                            solutionURL="./healthcare-app-solution"
                        />
                       
                        <SingleServiceFour
                            serviceIcon={img13}
                            Title="E-Commerce" 
                            solutionURL="./ecommerce-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img16}
                            Title="Fitness-Wellness" 
                            solutionURL="./fitness-wellness-app-solutions"
                        />
                        <SingleServiceFour
                            serviceIcon={img15}
                            Title="Real-Estate" 
                            solutionURL="./real-estate-app-solution"
                        />
                        <SingleServiceFour
                            serviceIcon={img17}
                            Title="Travel"
                            solutionURL="./travel-app-solutions" 
                        />
                        <SingleServiceFour
                            serviceIcon={img14}
                            Title="Buiness App"
                            solutionURL="./business-app-solution" 
                        />
                         <SingleServiceFour
                            serviceIcon={img11}
                            Title="Social Networking" 
                            solutionURL="./social-media-app"
                        />
                    </div>
                </div>
            </div> */}
            {/* rs-services-area-start */}

            {/* newsletter-area-start */}
            {/* <Accordian data={accordions.quality_engineering} /> */}
            <div className="accordions pb-50">
                <div className="container">
                    <FAQHeader data={faqDescriptions["software-development"]} />

                    <AccordionCards data={accordions.software_development} />
                </div>
            </div>
            <CTA
                ctaSectionClass="rs-cta style1 cta-bg1 pt-80 pb-60"
                ctaTitleClass="epx-title"
                ctaTitle="Hire Our Quality Engineers and Deliver Perfection"
                ctaTextClass="exp-text"
                ctaText="Our quality engineers help bring your software to life with in-depth testing and smooth optimization to ensure your product is reliable, efficient, and flawless."
                ctaText2="Our meticulous approach and high-quality tools will guarantee that you get even better results than you expected. Let's take your project to the next level of a highly performing masterpiece that end users will love."
                cta2TextClass="exp-text mannage"
                ctaButtonClass="readon learn-more"
                ctaButtonLink="#"
                ctaButtonText="Get In Touch"
            />
            {/* newsletter-area-end */}


            {/* working-process-area-start */}
            {/* <div className="rs-process style2 pt-80 pb-80">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text gold-color"
                        subtitle="Process"
                        titleClass="title title"
                        title="How we Works"
                        effectClass="heading-line" 
                        descClass="desc w-80"
                        description="As part of our approach to developing each project, our team uses agile methodologies to ensure your idea succeeds. No matter how large or small it is."
                        
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Discover & Define"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage13}
                                titleClass="title"
                                Title="Designing & Development"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage14}
                                titleClass="title"
                                Title="Testing & Deploying"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage15}
                                titleClass="title"
                                Title="Deliver & Maintenance"
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* working-process-area-end */}

            {/* brand-area-start */}
            {/* <Brand /> */}
            {/* brand-area-end */}

        </React.Fragment>
    )
}

export default Quality_Engineering;