import React from 'react'
import FeatureItem from "./FeatureItem";

const FeatureItems = ({featureItemsData}) => {
  return (
    <>
    {featureItemsData.map((item, index) => (
      <FeatureItem key={index} item={item} />
    ))}
  </>
  )
}

export default FeatureItems