import React from 'react';
import HeaderStyleThree from '../../components/Layout/Header/HeaderStyleThree';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Footer from '../../components/Layout/Footer';
import Microsoft_dev_m from '../../components/Common/Breadcumb/MicrosoftNETDev';
import Microsoft_dev_sec from './microsoftdev_Main';


// Breadcrumbs Background Image
import bannerbg from '../../assets/img/banner/banner-6.jpg';


const  Microsoft_dev = () => {

    return (
        <React.Fragment>
            <HeaderStyleThree
                parentMenu='page'
                secondParentMenu='services'
                activeMenu='/services'
            />
            <div>
                {/* breadcrumb-area-start */}
                < Microsoft_dev_m
                    pageTitle="Best-in-class services in Microsoft Technologies"
                    titleClass="page-title"
                    pageName="Microsoft.Net  
"
                    breadcrumbsImg={bannerbg}
                    animatedFont="lined-Font dance2"
                    animatedFontsize="Hire dedicated full-time .Net developers."
                />
                {/* breadcrumb-area-start */}

                {/* ServiceOneMain */}
                < Microsoft_dev_sec/>
                {/* ServiceOneMain */}
            </div>
            <Footer footerClass="rs-footer" />
            <SearchModal />
        </React.Fragment>

    );
}


export default Microsoft_dev;