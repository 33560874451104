import React from "react";

import postImage from "../../assets/img/blog/inner/5.jpg";

import { data } from "./data";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Description from "./Description";

const PostContent = () => {
  const { blogId } = useParams();

  const post = data.find((b) => b.id === Number(blogId));
  

  return (
    <div className="post-content">
      <h5>{post.title}</h5>
      <p>{post.intro[0]}</p>
      <p>{post.intro[1]}</p>
      <div className="bs-img mb-34">
        <img src={post.blogImage} alt="Post Image" />
      </div>
      <div className="blog-full">
        <ul className="single-post-meta">
          <li>
            <span className="p-date">
              <i className="fa fa-calendar"></i>
              {post.blogPublishedDate}
            </span>
          </li>
          <li>
            <span className="p-date">
              <i className="fa fa-user"></i>
              Admin
            </span>
          </li>
        </ul>
        {/* <h5>{post.sections[0].title}</h5> */}
        {post.sections.map((desc, index) => (
          <Description key={index} {...desc} />
        ))}
         <h5>{post.conclusion.title}</h5>
         <p>{post.conclusion.summary}</p>
      </div>
    </div>
  );
};

export default PostContent;
