import React from "react";


const Description = ({ title, Benefits, Challenges, Trends , Features,  CaseStudies}) => {
  console.log(Benefits);
  return (
    <div >
      <h3>{title}</h3>
      {Benefits &&
        Benefits.map((list, index) => (
          <div key={index}>
            <h3>
              {index + 1}. {list.title}
            </h3>
            <p>{list.description}</p>
            <p>{list.exampleUseCase}</p>
            <p>{list.subtitle}</p>
            <p>{list.solution }</p>
            {list.salesforceSolution &&
              list.salesforceSolution.map((point) => (
              
                  <ul>
                    <li>{point}</li>
                  </ul>
            
              ))}
            {list.blockchainSolution && <p>{list.blockchainSolution}</p>}
          </div>
        ))}
         {Features &&
        Features.map((list, index) => (
          <div key={index}>
            <h3>
              {index + 1}. {list.title}
            </h3>
            <p>{list.description}</p>
            <p>{list.blockchainSolution}</p>
          </div>
        ))}
      {Challenges &&
        Challenges.map((list, index) => (
          <div key={index}>
            <h3>
              {index + 1}. {list.title}
            </h3>
            <p>{list.description}</p>
            <p>{list.blockchainSolution}</p>
          </div>
        ))}
         { CaseStudies&&
         CaseStudies.map((list, index) => (
          <div key={index}>
            <h3>
              {index + 1}. {list.title}
            </h3>
            <p>{list.description}</p>
          </div>
        ))}
      {Trends &&
        Trends.map((list, index) => (
          <div key={index}>
            <h3>
              {index + 1}. {list.title}
            </h3>
            <p>{list.description}</p>
          </div>
          
        ))}
    </div>

  );
};

export default Description;
