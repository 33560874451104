import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';
import CTA from '../../components/Common/CTA';
// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import mainIcon55 from '../../assets/img/service/style2/main-img/55.png';
import mainIcon54 from '../../assets/img/service/style2/main-img/54.png';
import mainIcon41 from '../../assets/img/service/style2/main-img/41.png';
import mainIcon56 from '../../assets/img/service/style2/main-img/56.png';
import mainIcon48 from '../../assets/img/service/style2/main-img/48.png';

const WhyChoose = () => {

    return (
        <div id="rs-service" className="rs-services bg5 style3 pt-80 pb-0 md-pt-74 md-pb-60">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-60"
                    subtitleClass="sub-text gold-color"
                    subtitle="Solution"
                    titleClass="title testi-title text-white"
                    title="How MetaQualt Enhances Your Salesforce Experience"
                    descClass="desc text-dark"
                    description="As your dedicated Salesforce consulting partner, we collaborate closely to uncover and address the challenges your organization faces in leveraging Salesforce effectively. Whether it’s optimizing workflows, resolving inefficiencies, or improving user adoption, we tackle common pain points to help you get the most out of Salesforce."
                    effectClass="heading-line"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={mainIcon1}
                            Title="Boost ROI"
                           Text="We help you maximize value from your current Salesforce setup and build strong business cases for future investments, ensuring every step drives growth and success."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon55}
                            hoverIcon={mainIcon55}
                            Title="Driving Salesforce Adoption"
                           Text="We empower cross-functional teams to embrace Salesforce by showcasing its value, benefits, and best practices, ensuring seamless adoption and optimal use of its tools and technologies."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon54}
                            hoverIcon={mainIcon54}
                            Title="Your Salesforce Growth Partner"
                           Text="Unlock Salesforce’s full potential with a tailored roadmap that enhances and expands its capabilities over time."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="04"
                            mainIcon={mainIcon41}
                            hoverIcon={mainIcon41}
                            Title="Optimize the Customer Journey"
                           Text="Leverage data to gain insights into prospect, lead, and customer lifecycles, ensuring clear attribution and deeper engagement."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="05"
                            mainIcon={mainIcon56}
                            hoverIcon={mainIcon56}
                            Title="Delivering a 360° Customer View"
                            Text="Capture key insights to build stronger relationships, address current and future needs, and create meaningful customer experiences."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="06"
                            mainIcon={mainIcon48}
                            hoverIcon={mainIcon48}
                            Title="Your Growth Partner"
                            Text="Work with our certified professionals as an extension of your team, driving growth and long-term sustainability."
                        />
                    </div>
                </div>
            </div>
            {/* <CTA
                ctaSectionClass="rs-cta style1 pt-50 pb-50"
                ctaTitleClass="epx-title"
                ctaTitle="Build Next-Gen Fintech App with Us!"
                ctaTextClass="exp-text"
                ctaText="Let's s peak about it. Validate your concept for free with an experienced Fintech expert. Schedule your call right now."
                ctaButtonClass="readon learn-more"
                ctaButtonLink="./contact"
                ctaButtonText="Contact Us"
            /> */}
        </div>
    );
}

export default WhyChoose;